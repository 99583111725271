import {ReservationResponse} from '@/lib/kepler/interfaces'
import DateHelper from '@/lib/DateHelper'

type ReservationDuration = {
  elapsed: number | null;
  total: number | null;
  percent: number | null
}

export default class ReservationHelper {
  private readonly r: ReservationResponse

  constructor(obj: ReservationResponse) {
    this.r = obj
  }

  public static isCurrent(r: ReservationResponse) {
    let current: boolean = false
    const now = DateHelper.getTimestamp()
    const start = r.start_timestamp || 0
    const end = r.end_timestamp || 0
    const started = r.status === 'STARTED'
    const confirmed = r.status === 'CONFIRMED'
    if (start && end && (started || confirmed)) {
      current = now > start && end >= now
    }
    return current
  }

  public static duration(r: ReservationResponse) {
    const result: ReservationDuration = {
      elapsed: null,
      total: null,
      percent: null,
    }
    const getPercent = () => {
      if (result.elapsed && result.total) {
        return (result.elapsed / result.total) * 100
      }
      return null
    }
    if (this.isFuture(r)) {
      if (!r.type.endsWith('FF')) {
        result.total = r.end_timestamp - r.start_timestamp
      }
    } else if (this.isCurrent(r)) {
      if (r.type.endsWith('FF')) {
        result.elapsed = DateHelper.getTimestamp() - r.start_timestamp
      } else {
        result.total = r.end_timestamp - r.start_timestamp
        result.elapsed = DateHelper.getTimestamp() - r.start_timestamp
        result.percent = getPercent()
      }
    } else {
      result.total = r.end_timestamp - r.start_timestamp
      result.elapsed = r.end_timestamp - r.start_timestamp
      result.percent = getPercent()
    }
    return result
  }

  public static isFuture(r: ReservationResponse) {
    return (r.start_timestamp || 0) > DateHelper.getTimestamp()
  }

  public static isLate(r: ReservationResponse) {
    const rtLike = !r.type.endsWith('FF')
    const now = DateHelper.getTimestamp()
    const end = r.end_timestamp || 0
    return rtLike && (end <= now) && ['STARTED', 'CONFIRMED'].includes(r.status)
  }

  public static comparePositions(r1: ReservationResponse, r2?: ReservationResponse) {
    const sameLat = r1.current_vehicle_position?.latitude === r2?.current_vehicle_position?.latitude
    const sameLng = r1.current_vehicle_position?.longitude === r2?.current_vehicle_position?.longitude
    return sameLat && sameLng
  }

  public static same(r1: ReservationResponse, r2?: ReservationResponse) {
    return r2 !== undefined &&
      (r1.updated_timestamp === r2.updated_timestamp) && // sameUpdated
      (r1.start_timestamp === r2.start_timestamp) && // sameStart
      (r1.end_timestamp === r2.end_timestamp) && // sameEnd
      (r1.status === r2.status) && // sameStatus
      this.comparePositions(r1, r2) && // samePosition
      (JSON.stringify(r1.extra) === JSON.stringify(r2.extra))// sameExtra
  }
}
