



























import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {ValidationProvider} from 'vee-validate'
import {VIcon, VLayout, VSlideXReverseTransition, VTextField} from 'vuetify/lib'
import QrScanner from '@/components/QrScanner.vue'
import sdk from '@/lib/kepler/sdk'
import Utils from '@/utils'

@Component({
  components: {
    TextFieldWithValidation: Utils.loadComponent('fields/TextFieldWithValidation'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    ValidationProvider,
    VSlideXReverseTransition,
    VLayout,
    VIcon,
    VTextField,
  },
  name: 'Invite',
})
export default class Invite extends Vue {
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules: any

  @Prop({
    type: String,
    default: null,
  }) public readonly value!: string | null

  @Prop({
    type: String,
    default: null,
  }) public readonly options!: string | null

  @Prop() public name?: string
  @Prop() public description?: string

  public innerValue: string | null = null
  public loading: boolean = false

  public get opts() {
    return this.options?.split('|') || []
  }

  @Watch('innerValue')
  public onInnerValueChange(newVal: any) {
    if (newVal !== this.value) {
      sdk.checkToken(newVal)
        .then((r) => {
          if (r.data?.result_code === 'token-is-valid') {
            this.$emit('input', newVal)
          }
        })
        .catch(() => {
          this.$emit('input', null)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  @Watch('value', {immediate: true})
  public onValueChange(newVal: string) {
    this.innerValue = newVal
  }

  protected openQr() {
    this.$popup.open(QrScanner, {
      props: {
        callback: (val: string) => {
          this.loading = true
          this.innerValue = val
        },
      }, fullscreen: true, hideTopbar: true,
    })
  }
}
