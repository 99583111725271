





















import {Vue, Component} from 'vue-property-decorator'
import {Getter, State} from 'vuex-class'
import Utils from '@/utils'
import {HomeModeEnum, ReservationResponse} from '@/lib/kepler/interfaces'

@Component({
  components: {
    TopBar: Utils.loadComponent('TopBar'),
    ActiveReservationBar: Utils.loadComponent('entities/reservation/ActiveReservationBar'),
    MaintenanceReservationBar: Utils.loadComponent('entities/reservation/MaintenanceReservationBar'),
  },
})
export default class AppTopBar extends Vue {
  @State((state) => state.configuration.homeMode) public homeMode!: HomeModeEnum
  @Getter('activeReservations') public activeReservations!: ReservationResponse[]
  @Getter('maintenance') public isMaintenance!: boolean

  protected get isHome() {
    return ['home', 'map'].includes(this.$route.name || '')
  }

  protected get hasReservations(): boolean {
    return !!(this.isHome && this.activeReservations.length)
  }
}
