





























import {Vue, Component, Prop} from 'vue-property-decorator'
import {
  DamagePosition, Report, DamagePoints as Dmps,
} from '@/lib/kepler/interfaces'
import {State} from 'vuex-class'
import {AppConfigState} from '@/store/modules/configuration'
import Utils from '@/utils'

@Component({
  components: {
    Img: Utils.loadComponent('proxy/Image'),
    DamagePoints: Utils.loadComponent('DamagePoints'),
    VehicleQuickInfo: Utils.loadComponent('entities/vehicle/VehicleQuickInfo'),
    Divider: Utils.loadComponent('proxy/Divider'),
    CardButton: Utils.loadComponent('CardButton'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Container: Utils.loadComponent('proxy/Container'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
  },
  name: 'VehicleReport',
})
export default class VehicleReport extends Vue {
  @State('configuration') public configState!: AppConfigState
  @Prop() protected report!: Report
  private damagePoints: Dmps[] | undefined = []

  private carOutline!: any

  protected get vehicleSlot() {
    return {vehicle: this.report.vehicle}
  }

  protected get imgOutline() {
    return this.report.vehicle.category.damage_picture
  }

  protected get statusFormat() {
    const s = this.report.status

    let color = 'accent'

    switch (s) {
      case 'CONFIRMED':
      case 'RESOLVED':
        color = 'success'
        break
      case 'PENDING_REVIEW':
        color = 'warning'
        break
      case 'VOIDED':
        color = 'grey'
    }

    return {text: this.$t(`vehicle.report.status.${s.toLowerCase()}`), color}
  }

  protected mounted() {
    setTimeout(() => {
      this.carOutline = this.$refs.carimg
      this.getDamagePoints()
    }, 400)
  }

  private getDamagePoints() {
    if (this.report.damage_position_raw) {
      this.report.damage_position_raw.forEach((dmg: DamagePosition) => {
        this.damagePoints!.push({
          title: this.report.id,
          left: ((dmg.left) * (this.$refs.carimg as any).clientWidth) / dmg.containerWidth,
          top: ((dmg.top) * (this.$refs.carimg as any).clientHeight) / dmg.containerHeight,
        })
      })
    }
  }

}
