
















import {Component, Prop, Watch} from 'vue-property-decorator'
import Utils from '@/utils'
import {
  ReservationResponse,
  BookTimeSelectRequest,
  ReservationEditRequest,
} from '@/lib/kepler/interfaces'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'

@Component({
  components: {
    DateTimeRangeField: Utils.loadComponent('fields/DateTimeRangeField'),
    Container: Utils.loadComponent('proxy/Container'),
    RangeEstimate: Utils.loadComponent('rangeSelector/rangeEstimate'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    VehicleQuickSummary: Utils.loadComponent('entities/vehicle/VehicleQuickSummary'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'BookingEdit',
})
export default class BookingEdit extends ConfirmDialogCallback {
  @Prop({type: Object, required: true}) public readonly reservation!: ReservationResponse
  declare public confirmCallback: (r: ReservationEditRequest) => void

  protected innerValue: BookTimeSelectRequest = {start: '', end: ''}

  protected valid = false

  @Watch('innerValue', {deep: true})
  protected isValid(val: BookTimeSelectRequest) {
    if (!val || !val.start || !val.end) {
      this.valid = false
      return
    }
    const empty: boolean = this.reservation.start === null || this.reservation.end === null
    const start = this.reservation.start
    const end = this.reservation.end
    const newStart = val.start
    const newEnd = val.end
    this.valid = !((start === newStart) && (end === newEnd) || empty)
  }

  protected confirm() {
    this.callAndClose(() => this.confirmCallback({
      start: this.innerValue.start || '',
      end: this.innerValue.end || '',
      reservation_number: this.reservation.number,
    }))
  }

  protected created() {
    if (this.reservation.start && this.reservation.end) {
      this.innerValue = {
        start: this.reservation.start,
        end: this.reservation.end,
      }
    } else {
      this.$dialog.close()
    }
  }
}
