import {Component, Vue} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {AppConfig, BookingMesh, BookingMode, FieldConfigs, Pos, VehicleType} from '@/lib/kepler/interfaces'
import {ItemsResponse, MapItemsState, SearchParameters} from '@/store/modules/map'
import {EventBus} from '@/main'
import {FilterGroup, FilterStructure} from '@/store/modules/filters'
import ServiceMesh from '@/lib/serviceMesh'

@Component
export class FiltersLogic extends Vue {
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig
  @State((state) => state.mapItems.itemsResponse) public itemsResponse!: ItemsResponse
  @State((state) => state.filters.filtersCustom) public readonly customFilters!: FilterStructure | null
  @State((state) => state.filters.filtersDefault) public readonly defaultFilters!: FilterStructure | null

  @Getter('fieldConfigs') public fieldConfigs!: FieldConfigs
  @Getter('userPosition') public userPosition!: Pos
  @Getter('mesh') public mesh!: BookingMesh[]

  @Action('updateDefaultFilters') public updateDefaultFilters!: (config: FilterStructure) => void
  @Action('setCustomFilters') public setCustomFilters!: (p: FilterStructure | null) => void
  @Action('_initFilters') public initFilters!: () => Promise<void>
  @Action('_toggle') public toggle!: (path: [keyof MapItemsState, string]) => boolean | null
  @Action('_parameter') public updateParameter!: (p: SearchParameters) => void

  public loading: boolean = false
  public modified: boolean = false

  protected init(recenter: boolean = true, reset = false) {
    if (!this.loading) {
      this.loading = true
      // initialize filters based on user position and create config
      this.initFilters()
        .finally(() => {
          this.loading = false
          this.modified = false
        })
        .then(() => {
          if (!this.defaultFilters || reset) {
            this.loading = true
            this.setCustomFilters(null)
            this.generateDefaultFilters().finally(() => { this.loading = false })
          } else {
            this.generateDefaultFilters()
          }
        })
    }
  }

  protected async generateDefaultFilters(): Promise<FilterStructure> {
    const metaConfig = this.fieldConfigs.filters_meta_fields

    const result: FilterStructure = {
      meta: {
        range_selector: !!metaConfig?.includes('range_selector'),
        time_picker: !!metaConfig?.includes('time_picker'),
      },
      groups: [],
    }

    if (this.mesh) {
      const vGroup: FilterGroup = {
        id: 'vehicles',
        title: '',
        items: [],
      }
      const serviceMesh = new ServiceMesh()

      this.mesh.forEach((sm) => {
        const bm = sm.booking_mode as BookingMode
        const vt = sm.vehicle_type as VehicleType
        const bmvt = (bm + vt).toUpperCase()
        const override = this.$isAvailable(`filters.${(bm + vt).toUpperCase()}`)
        const name = override || this.$t(`activities.booking.${vt.toLowerCase()}.${bm.toLowerCase()}.action`)

        vGroup.items.push({
          color: ServiceMesh.colors[(bmvt).toUpperCase()],
          enabled: true,
          icon: serviceMesh.getImage(bm, vt),
          id: bmvt,
          name,
          vehicle_types: [vt],
          booking_mode: [bm],
        })
      })

      result.groups.push(vGroup)
    }
    if (this.itemsResponse.zones?.length) {
      const szGroup: FilterGroup = {
        id: 'zones',
        title: this.$t('map.filters.service_zones'),
        items: [],
      }
      this.itemsResponse.zones.forEach((sz) => {
        szGroup.items.push({
          enabled: true,
          id: sz.id,
          name: sz.name,
          small: true,
          zones: [sz.id],
        })
      })
      if (szGroup.items.length) {
        result.groups.push(szGroup)
      }
    }
    if (this.itemsResponse.parking?.length) {
      const plGroup: FilterGroup = {
        id: 'park',
        title: this.$t('filters.parking_zones'),
        items: [
          {
            enabled: true,
            id: 'parkings',
            name: this.$t('filters.parking_lots'),
            small: true,
            parkings: [...this.itemsResponse.parking.map((p) => p.id)],
          },
        ],
      }

      result.groups.push(plGroup)
    }
    if (this.itemsResponse.poi?.length) {
      const poiGroup: FilterGroup = {
        id: 'poi',
        title: this.$t('map.filters.points_of_interest'),
        items: [],
      }
      this.itemsResponse.poi.forEach((poi) => {
        poiGroup.items.push({
          enabled: true,
          id: poi.id,
          name: poi.display_text,
          small: true,
          poi: [poi.id],
        })
      })
      if (poiGroup.items.length) {
        result.groups.push(poiGroup)
      }
    }

    this.updateDefaultFilters(result)
    return result
  }

  protected created() {
    EventBus.$on('recenter', this.init) // recenter should be called as soon as position is given
  }

  protected destroyed() {
    EventBus.$off('recenter', this.init)
  }
}
