


















import {Component, Prop, Vue} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    EmptyList: Utils.loadComponent('EmptyList'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
  },
})
export default class FilteredList extends Vue {
  @Prop({type: Boolean, default: false}) public empty!: boolean
  @Prop({type: Boolean, default: false}) public hideFilters!: boolean
  @Prop({type: Boolean, default: false}) public filterHasResults!: boolean
  @Prop({type: Boolean, default: false}) public loading!: boolean
}
