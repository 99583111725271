






















































import {Vue, Component} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {Notification} from '@/lib/kepler/interfaces'
import {$datetimeformat} from '@/lib/plugins/lang'
import DateHelper from '@/lib/DateHelper'
import Utils from '@/utils'

@Component({
  components: {
    CloseButton: Utils.loadComponent('CloseButton'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Dialog: Utils.loadComponent('proxy/Dialog'),
    Btn: Utils.loadComponent('proxy/Btn'),
    FilteredList: Utils.loadComponent('FilteredList'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Button: Utils.loadComponent('Button'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CardList: Utils.loadComponent('CardList'),
    List: Utils.loadComponent('proxy/List/List'),
  },
})
export default class Communications extends Vue {
  @State('notifications') protected notifications !: Notification[]
  @Getter('unreadNotifications') protected unread !: Notification[]
  @Action('getNotifications') protected getNotifications!: () => void
  @Action('notificationDelete') protected deleteNotification!: (id: string) => void
  @Action('notificationDeleteAll') protected deleteNotificationAll!: () => void
  @Action('notificationRead') protected readNotification!: (id: string) => void
  @Action('notificationReadAll') protected readNotificationAll!: () => void
  protected messageDetail: Notification | null = null
  protected dialog: boolean = false
  protected showRead: boolean = true

  protected get n() {
    return this.showRead ? this.notifications.reverse() : this.unread.reverse()
  }

  protected openDialog(n: Notification) {
    if (!n.read) {
      this.readNotification(n.id)
    }
    this.messageDetail = n
    this.dialog = true
  }

  protected closeDialog() {
    this.messageDetail = null
    this.dialog = false
  }

  protected openUrl(url: string) {
    cordova.InAppBrowser.open(url, '_system')
  }

  protected formatDate(n: Notification) {
    return DateHelper.formatDate(n.timestamp, $datetimeformat())
  }

  protected created() {
    this.getNotifications()
  }
}
