

















import {Component, Prop, Vue} from 'vue-property-decorator'

import {VCheckbox} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'
import Button from '@/components/Button.vue'
import sdk from '@/lib/kepler/sdk'
import Input from '@/components/proxy/Inputs/Input.vue'
import {ProviderInstance} from 'vee-validate/dist/types/types'
import CheckboxFieldWithValidation from '@/components/fields/CheckboxFieldWithValidation.vue'
import CheckBox from '@/components/proxy/Inputs/CheckBox.vue'
import Layout from '@/components/proxy/Layout.vue'

@Component({
  components: {Layout, CheckBox, CheckboxFieldWithValidation, Input, Button, VCheckbox, ValidationProvider},
  name: 'VerificationField',
})
export default class VerificationField extends Vue {

  @Prop() public readonly value?: boolean | null
  @Prop({type: Boolean, default: false}) public readonly disabled!: boolean
  @Prop({
    type: Object,
    default: () => new Object({provider: undefined}),
  }) public readonly options!: { provider?: string, url?: string }
  @Prop({
    type: Boolean,
    default: '',
  }) public readonly required!: boolean
  @Prop({
    type: String,
    required: false,
  }) public name?: string
  @Prop({
    type: String,
    required: false,
  }) public description?: string

  public loading: boolean = false
  public providerInstance: ProviderInstance | null = null

  public get innerValue() {
    return this.value ?? null
  }

  public set innerValue(v: boolean | null) {
    this.providerInstance?.validate(v)
    this.$emit('input', v)
  }

  protected get classes(): Record<string, boolean> {
    return this.providerInstance?.classes || {}
  }

  protected verify() {
    this.loading = true
    const provider = this.options.provider
    this.providerInstance?.reset()
    sdk.profile.get_verification(provider).then(({data}) => {
      if (data.provider === 'veriff' && 'Veriff' in window) {
        const w = window as Window & typeof globalThis & { Veriff?: any }
        w.Veriff?.run(
          data.payload.url,
        ).then(() => {
          this.innerValue = true
        }).catch(() => {
          this.innerValue = false
        }).finally(() => {
          this.loading = false
        })
      } else if (data.payload.url) {
        cordova.InAppBrowser.open(data.payload.url, '_system')
        this.innerValue = true
        this.loading = false
      } else {
        this.$log(`verification provider ${data.provider} is not implemented`, 3)
        this.innerValue = false
        this.loading = false
      }
    })

  }

  protected mounted() {
    this.providerInstance = this.$refs.provider as ProviderInstance
  }
}
