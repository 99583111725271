























































































































import {Component, Vue} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {
  AppConfig,
  Dialog,
  LoginByPhoneCodeRequest,
  LoginProvider,
  LoginProviderName,
  LoginRequest,
  LoginResponse,
} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

import ResetPasswordView from '@/views/ResetPassword.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import {VSlideYTransition} from 'vuetify/lib'
import PhoneVerificationView from '@/views/PhoneVerification.vue'
import Validations from '@/lib/Validations'
import smoothReflow from 'vue-smooth-reflow'
import sdk from '@/lib/kepler/sdk'
import {AxiosError} from 'axios'
import ErrorView from './Error.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import FlowDialog from '@/views/Flows/FlowDialog.vue'
import FlowAutoload from '@/views/Flows/FlowAutoload.vue'
import {FlowInputsState} from '@/store/modules/flowInputs'

@Component({
  components: {
    CustomIcon,
    Sheet: Utils.loadComponent('proxy/Sheet'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    Img: Utils.loadComponent('proxy/Image'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Button: Utils.loadComponent('Button'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CloseButton: Utils.loadComponent('CloseButton'),
    PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    VSlideYTransition,
  },
  name: 'Login',
  mixins: [smoothReflow],
})
export default class Login extends Vue {
  @State((state) => state.configuration.appConfig) public appConfig?: AppConfig
  @State('flowInputs') public flowInputs!: FlowInputsState
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }

  @Getter('loginProviders') public loginProviders!: LoginProvider[]
  @Action('loadConfig') public loadConfig: any
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('switchProfile') public switchProfile!: (token: string) => Promise<void>
  @Action('editField') public editField!: (payload: { path: string[], value: any }) => void

  @Action('login') public login!: (req: LoginRequest) => Promise<LoginResponse>
  @Action('requestLoginByPhone') public requestLoginByPhone!: (req: LoginByPhoneCodeRequest) => Promise<any>

  protected rules = Validations.rules
  protected showPassword: boolean = false
  protected credentials: LoginRequest = {username: '', password: ''}
  protected loading: boolean = false
  protected valid: boolean = false
  protected phone: string = ''
  protected dialog = false
  protected dialogSMSlogin = false

  protected usernameRules: any = [
    (v: any) => !!v || 'Name is required',
  ]

  protected passwordRules: any = [
    (v: any) => !!v || 'Password is required',
  ]

  protected socialLoginMaxTries = 30
  protected socialLoginTries = this.socialLoginMaxTries
  protected socialLoginTimeout: NodeJS.Timeout | null = null
  protected socialLoginDialog: boolean = false

  protected get registrationMode() {
    return this.appConfig?.registration_mode || 'enabled'
  }

  protected get hackerMode() { // 🕶 //
    const hash = '50457b728b94662722782bc66503205a'
    return this.credentials?.username === hash && this.credentials?.password === hash
  }

  protected get smsLoginEnabled() {
    return this.appConfig?.sms_login_enabled ?? true
  }

  protected get smsLogin() {
    if (this.dialog) {
      return this.dialogSMSlogin
    }
    return this.$route.hash === '_by_phone' || this.$route.path === '/login_by_phone'
  }

  protected set smsLogin(val: boolean) {
    if (!this.dialog) {
      this.$router.replace({name: 'login', hash: val ? '_by_phone' : ''})
    } else {
      this.dialogSMSlogin = val
    }
  }

  protected loginRequest() {
    if ((this.$refs as any)?.loginForm?.validate()) {
      this.loading = true
      this.login(this.credentials).then(() => {
        if (this.$route.name !== 'home') {
          this.$router.push({name: 'home'})
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }

  protected toggleSmsLogin() {
    this.smsLogin = !this.smsLogin
  }

  protected checkSMS(title?: string) {
    this.openDialog(new Dialog(PhoneVerificationView, {
      mobileNumber: this.phone, isDialog: true, quick: false, title, callback: () => {
        this.$popup.close()
        this.$dialog.close()
        if (this.$route.name !== 'home') {
          this.$router.push({name: 'home'})
        }
      },
    }))
  }

  protected verifyPhone() {
    if ((this.$refs as any).loginForm?.validate()) {
      this.loading = true
      sdk.people.requestLoginByPhone({mobile_number: this.phone})
        .then(() => {
          this.checkSMS()
        })
        .catch((e: AxiosError) => {
          this.$dialog.close()
          this.checkSMS(e.response?.data?.messages?.[0])
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  protected openRegistration() {
    this.$popup.close()
    this.$router.push({name: 'registration'})
  }

  protected openResetPasswordView() {
    this.$popup.open(ResetPasswordView, {title: this.$t('login.reset_your_password')})
  }

  protected ee() {
    this.openDialog(new Dialog(ConfirmDialogCallback, {
      imageState: null,
      code: null,
      title: null,
      subtitle: 'using md5 and tapping on random red buttons doesn\'t make you a hacker',
      cancelText: 'oh no',
      confirmText: this.$t('common.ok'),
      emitConfirm: false,
      showCloseButton: false,
      confirmColor: 'error',
      confirmCallback: () => {
        this.$router.push('/dev')
      },
    }))
  }

  protected mounted() {
    this.$smoothReflow?.({
      property: ['height'],
      el: 'form.login-form',
      hideOverflow: true,
      transitionEvent: {
        selector: '.layout',
      },
    })
  }

  protected loginWithProvider(id?: LoginProviderName) {
    if (this.loading) {
      return
    }
    this.loading = true
    sdk.login.requestProvider(id)
      .then(({data}) => {
        if (data.auth_url && data.token) {
          const token = data.token
          this.autoCheckToken(token)
          setTimeout(() => {
            const onResume = () => {
              setTimeout(() => {
                this.autoCheckToken(token)
                document.removeEventListener('resume', onResume)
              }, 0)
            }
            document.addEventListener('resume', onResume, false)
            cordova.InAppBrowser.open(data.auth_url, '_system')
          }, 333)
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  protected closeSocialLogin() {
    if (this.socialLoginTimeout) {
      clearTimeout(this.socialLoginTimeout)
      this.socialLoginTries = this.socialLoginMaxTries
      this.socialLoginTimeout = null
      this.socialLoginDialog = false
    }
    this.loading = false
  }

  /* tslint:disable: cyclomatic-complexity */ // argh
  protected autoCheckToken(token: string) {
    if (!this.loading) {
      return
    }
    this.socialLoginDialog = true
    sdk.login.check(token).then((data) => {
      const d = data.data
      const s = d.status

      if (d.flow_data && d.flow_open) {
        for (const [k, value] of Object.entries(d.flow_data)) {
          const path = (d.flow_open + '.' + k).split('.')
          this.editField({path, value})
        }
      }

      if (s === 'token_missing') {
        this.handleMissingToken(token)
      } else if (s === 'not_found') {
        this.handleNotFound(d.email)
      } else if (s === 'login' && d.token) {
        this.switchProfile(d.token)
        this.closeSocialLogin()
        this.$router.replace('/')
      }
      if (d.flow_open) { // maybe "s === 'flow'" or something?
        this.openFlow(d.flow_open)
      }
    })
  }

  protected handleMissingToken(token: string) {
    if (this.socialLoginTries > 0) {
      this.socialLoginTries--
      this.socialLoginTimeout = setTimeout(() => {
        this.autoCheckToken(token)
      }, 888)
    } else {
      this.closeSocialLogin()
      this.$dialog.open(ErrorView, {
        props: {
          title: this.$t('login.timeout.title'),
          subtitle: this.$t('login.timeout.subtitle'),
        },
      })
    }
  }

  protected handleNotFound(email: string = '') {
    this.closeSocialLogin()
    this.$dialog.open(ConfirmDialogCallback, {
      props: {
        code: '',
        title: this.$t('login.not_found.title'),
        subtitle: this.$t('login.not_found.subtitle', {email}),
        confirmText: this.$t('login.create_one'),
        singleAction: true,
        confirmCallback: () => {
          this.openRegistration()
        },
        cancelCallback: () => {
          //
        },
      },
    })
  }

  protected openFlow(flowName?: string) {
    const flow = this.flowInputs[flowName || '']
    if (flowName && flow) {
      if (flow.dialog) {
        this.$dialog.open(FlowDialog, {props: {flowName}, title: flowName})
      } else {
        this.$popup.open(FlowAutoload, {props: {flowName}, hideTopbar: true})
      }
    }
  }
}
