








































import {Component, Prop} from 'vue-property-decorator'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import {
  EstimateCurrent,
  ReservationResponse,
  TerminateResponse,
} from '@/lib/kepler/interfaces'
import {duration} from 'moment'
import DateHelper from '@/lib/DateHelper'
import Utils from '@/utils'
import sdk from '@/lib/kepler/sdk'

interface Estimate {
  time: string
  distance: string
  cost: string
}

@Component({
  methods: {duration},
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Icon: Utils.loadComponent('proxy/Icon'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
})
export default class ConfirmTerminateDialog extends ConfirmDialogCallback {
  @Prop({type: Object}) public reservation!: ReservationResponse
  @Prop({type: Object}) public response!: TerminateResponse

  protected canRequest = true
  protected estimate: Estimate | null = null
  protected disclaimer = this.$isAvailable(
    'booking.terminate_confirmed.disclaimer',
  )

  protected getEstimate(r: EstimateCurrent) {
    const cost = r.cost ? this.$currency(r.cost) : ''
    const distance = r.distance ? this.$distance(r.distance) : ''
    const formatTime = (s: number) => DateHelper.formatTimeOffset('dhm', s)
    const time = r.duration !== undefined ? formatTime(r.duration * 60) : ''
    this.estimate = {cost, distance, time}
  }

  protected requestEstimate() {
    this.loading = true
    sdk.booking
      .estimateCurrent(this.reservation.number)
      .then((r) => {
        this.getEstimate(r.data)
        this.canRequest = false
      })
      .finally(() => {
        this.loading = false
      })
  }
}
