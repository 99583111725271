






























import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import Utils from '@/utils'
import {
  AvailabilityResponse,
  BookTimeSelectRequest,
  ReservationExtendRequest,
  ReservationResponse,
  UserMesh,
} from '@/lib/kepler/interfaces'

@Component({
  components: {
    RangeSelector: Utils.loadComponent('rangeSelector/rangeSelector'),
    RangeEstimate: Utils.loadComponent('rangeSelector/rangeEstimate'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'QuickExtendDialog',
})
export default class QuickExtendDialog extends Vue {
  @State((state) => state.profile.userMesh) public userMesh!: UserMesh[] | undefined

  @Action('getUserMesh') public getUserMesh: any
  @Action('extendReservation') public extendReservation!: (p: ReservationExtendRequest) => Promise<ReservationResponse>

  @Prop() public reservation!: ReservationResponse
  @Prop() public availability!: AvailabilityResponse[]
  @Prop() public onConfirm!: () => void

  public loading: boolean = false
  public estimateRequest: BookTimeSelectRequest | null = null

  protected get hasBookingModeSubscription() {
    const mesh = this.userMesh
    const vs = this.reservation.vehicle_slot
    if (mesh === undefined) {
      this.loading = true
      this.getUserMesh()
      return 'loader'
    }
    let value = false
    mesh.forEach((mode: UserMesh) => {
      const matchBM = mode.booking_mode.toLowerCase() === vs.reservation_type.toLowerCase()
      const matchVT = mode.vehicle_type.toLowerCase() === vs.vehicle.category.type.toLowerCase()
      if (matchBM && matchVT && mode.available) {
        value = true
      }
    })
    return value
  }

  protected get originalDuration() {
    return {
      start: this.reservation.start,
      end: this.reservation.end,
    }
  }

  protected getEstimate(obj: BookTimeSelectRequest) {
    this.$set(this, 'estimateRequest', obj)
  }

  protected confirm() {
    if (this.estimateRequest?.end) {
      this.loading = true
      this.extend(this.estimateRequest.end, this.reservation.number)
    }
  }

  protected extend(end: string, reservation_number: number) {
    this.loading = true
    this.extendReservation({reservation_number, end})
      .then(this.onConfirm)
      .finally(() => {
        this.loading = false
      })
  }
}
