






































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {ValidationProvider} from 'vee-validate'
import {FlowInputStep} from '@/lib/kepler/interfaces'
import {
  VCard,
  VContainer,
  VDialog,
  VDivider,
  VExpansionPanel,
  VExpansionPanelContent,
  VIcon,
  VImg,
  VLayout,
  VTextField,
} from 'vuetify/lib'
import CardButton from '@/components/CardButton.vue'
import ColorCard from '@/components/ColorCard.vue'
import VehicleFeatures from '@/components/entities/vehicle/VehicleFeatures.vue'
import CloseButton from '@/components/CloseButton.vue'

export interface Choice {
  value: string,
  text?: string,
  title?: string
  color?: string,
  icon?: string,
  image?: string,
  extendedtext?: string,
  price?: string | number,
  needs?: string
  exclude?: string
  min?: string | number
  max?: string | number
  subtitle?: string
  attributes?: Array<{
    name: string
    description: string
    id: string
    type: string
    value: string
    slug: string
    recommended: number,
  }>
  info?: string
}

@Component({
  components: {
    CloseButton,
    VContainer,
    VehicleFeatures,
    ColorCard,
    ValidationProvider,
    CardButton,
    VLayout,
    VTextField,
    VIcon,
    VImg,
    VExpansionPanel,
    VExpansionPanelContent,
    VCard,
    VDivider,
    VDialog,
  },
  name: 'MultipleChoice',
})
export default class MultipleChoice extends Vue {
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules: any

  @Prop({
    type: Array || null,
    default: null,
  }) public readonly value!: string[] | null

  @Prop({
    type: Array,
    default: () => [],
  }) public readonly items!: Choice[]

  @Prop() public step?: FlowInputStep
  @Prop({
    type: String,
    required: false,
  }) public name?: string
  @Prop({
    type: String,
    required: false,
  }) public description?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public disabled?: boolean

  public innerValue: string[] | null = null
  public accordion: Array<boolean | null> = []
  public infoVisible: boolean = false
  public infoContent: string | null = null

  public get singleChoice() {
    return this.rules.includes('single')
  }

  public get filteredItems(): Choice[] {
    if (this.disabled && this.rules.includes('showOnlySelected')) {
      return this.items.filter((i) => this.value?.includes(i.value))
    }
    return this.items
  }

  @Watch('innerValue')
  public onInnerValueChange(newVal: string[]) {
    if (newVal !== this.value) {
      this.$emit('input', newVal)
    }
  }

  @Watch('value', {immediate: true})
  public onValueChange(newVal: string[]) {
    if (!this.innerValue && this.value) {
      this.$emit('input', this.value)
    }
    this.innerValue = newVal
  }

  public isSelected(value: string) {
    return this.innerValue?.includes(value) || false
  }

  public needs(value: string) {
    const item = this.items.find((i) => i.value === value)
    const needed = item?.needs?.split('|') || []
    return needed.length ? this.items.filter((i) => needed.includes(i.value)) : []
  }

  public exclude(value: string) {
    const item = this.items.find((i) => i.value === value)
    const excluded = item?.exclude?.split('|') || []
    return excluded.length ? this.items.filter((i) => excluded.includes(i.value)) : []
  }

  public isNeededBy(value: string) {
    return this.items.filter((i) => i.needs?.includes(value))
  }

  public isExcludedBy(value: string) {
    return this.items.filter((i) => i.exclude?.includes(value))
  }

  public choiceIcon(value: string) {
    let n
    const arr = [
      'mdi-radiobox-blank',
      'mdi-checkbox-blank-outline',
      'mdi-radiobox-marked',
      'mdi-checkbox-marked',
    ]
    n = this.singleChoice ? 0 : 1
    n += this.isSelected(value) ? 2 : 0
    return arr[n]
  }

  public toggle(value: string) {
    // debug
    // const enabled = this.isSelected(value)
    // const include = this.needs(value)
    // const exclude = this.exclude(value)
    // const isNeededBy = this.isNeededBy(value)
    // const isExcludedBy = this.isExcludedBy(value)
    // console.log(
    //   `${enabled ? 'deselecting' : 'selecting'} ${value}! \n`,
    //   'needs', include,
    //   'excludes', exclude,
    //   'is needed by', isNeededBy,
    //   'is excluded by', isExcludedBy,
    // )
    if (this.isSelected(value)) {
      this.disable(value)
    } else {
      this.enable(value)
    }
  }

  public disable(value: string, skipCheck?: boolean) {
    const item = this.items.find((i) => i.value === value)
    if (this.innerValue?.length) {

      if (!skipCheck) {

        if (this.singleChoice && this.rules.includes('required')) {
          return
        }

        const isNeededBy = this.isNeededBy(value)
        if (isNeededBy.length) {
          isNeededBy.forEach((i) => {
            if (this.isSelected(i.value)) {
              // TODO: replace with better dialog
              if (confirm(`"${item?.title}" is needed by "${i.title}"! clicking OK you will deselect that option too`)
              ) {
                this.disable(i.value, true)
              } else {
                return
              }
            }
          })
        }
      }

      // filter out the value and save
      this.innerValue = this.innerValue
        .filter((v) => v !== value)
        .sort((a, b) => a.localeCompare(b))
    }
  }

  public enable(value: string, skipCheck?: boolean) {
    if (!skipCheck && !this.enableChecks(value)) {
      return
    }

    if (this.singleChoice || !this.innerValue?.length) {
      this.innerValue = [value]
    } else {
      const arr = [...this.innerValue]
      if (!arr.includes(value)) {
        arr.push(value)
      }
      this.innerValue = arr.sort((a, b) => a.localeCompare(b))
    }
  }

  public enableChecks(value: string) {
    const item = this.items.find((i) => i.value === value)

    const checkExcluded = (arr: Choice[]) => {
      return arr
        .filter((i) => this.isSelected(i.value))
        .map((i) => i.title)
    }

    const excluded = checkExcluded(this.exclude(value))
    if (excluded.length) {
      alert(`"${item?.title}" is not compatible with "${excluded.join('", "')}"`)
      return false
    }

    const isExcludedBy = checkExcluded(this.isExcludedBy(value))
    if (isExcludedBy.length) {
      alert(`"${item?.title}" is not compatible with "${isExcludedBy.join('", "')}"`)
      return false
    }

    const needs = this.needs(value)
    if (needs.length) {
      const arr: string[] = []
      needs.forEach((i) => {
        if (!this.isSelected(i.value)) {
          arr.push(i.title || i.value)
          this.enable(i.value, true)
        }
      })
      if (arr.length) {
        alert(`this option selected "${arr.join('", "')}" as well`)
      }
    }
    return true
  }

  public openAccordion(i: number) {
    const v = this.accordion[i]
    if (v !== null) {
      Vue.set(this.accordion, i, !v)
    }
  }

  public openInfo(info: string) {
    this.infoVisible = true
    this.infoContent = info
  }

  protected created() {
    if (this.value) {
      if (Array.isArray(this.value)) {
        this.value.forEach((v) => this.enable(v))
      } else {
        this.enable(this.value)
      }
    }
    this.accordion = this.items.map((i) => i.extendedtext ? false : null)
  }

  protected price(val: string | number) {
    return typeof val === 'number' ? this.$currency(val) : val
  }

}
