import {Vue, Component} from 'vue-property-decorator'
import {BookingMode, BookRequest, FieldConfigs, ReservationResponse, VehicleType} from '@/lib/kepler/interfaces'
import LoaderDialog from '@/views/LoaderDialog.vue'
import VehicleConfirmBooking from '@/views/Vehicle/VehicleConfirmBooking.vue'
import {Action, Getter} from 'vuex-class'

@Component({})
export default class BookingMixin extends Vue {
  @Getter('fieldConfigs') public fieldConfigs!: FieldConfigs
  @Action('book') public bookAction!: (req: BookRequest) => Promise<ReservationResponse>

  protected bookRequest: BookRequest = {start: '', end: '', memo: null, plate: '', packet_id: null}

  protected sendBooking(callback?: (r?: ReservationResponse) => void) {
    this.$dialog.open(LoaderDialog, {props: {text: this.$t('common.loading')}})
      .then((id) => {
        this.bookAction(this.bookRequest)
          .finally(() => {
            this.$dialog.close(id)
          })
          .then((r) => {
            const confirmCallback = callback ? () => callback(r) : undefined
            this.$popup.open(VehicleConfirmBooking, {
              props: {vehicleSlot: r.vehicle_slot, confirmCallback}, hideTopbar: true,
            })
          })
          .catch(() => '💩')
      })
  }

  protected disableMemo(bm?: null | keyof typeof BookingMode, vt?: null | keyof typeof VehicleType) {
    const conf = this.fieldConfigs.disable_memo_dialog?.toLowerCase().split('|')

    if (conf && bm && vt) {
      return conf.includes((bm + vt).toLowerCase())
    }
    return conf
  }

}
