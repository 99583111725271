




























import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import Utils from '@/utils'
import {
  BookTimeSelectRequest,
  Estimate,
  EstimateRequest,
  FieldConfigs,
  VehicleSlot,
} from '@/lib/kepler/interfaces'
import {Action, Getter} from 'vuex-class'
import moment from 'moment'

@Component({
  components: {
    RangeSelector: Utils.loadComponent('rangeSelector/rangeSelector'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'RangeEstimate',
})
export default class RangeEstimate extends Vue {
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  @Action('bookEstimate') public bookEstimate!: (r: EstimateRequest) => Promise<Estimate>

  @Prop() public vehicleSlot!: VehicleSlot
  @Prop({type: Object}) public readonly requestForEstimate!: BookTimeSelectRequest | null
  @Prop({type: Object, required: false}) public readonly extend?: { start: string, end: string }

  public estimate: Estimate = {distance_cost: 0, time_cost: 0}
  public extendEstimate: Estimate | null = null

  public estimateLoader: boolean = false

  protected get noEstimate() {
    const checkList = [
      this.estimateConfig,
      this.estimateDistance,
      this.estimateTime,
      this.estimateTotal,
    ]
    return checkList.every((v) => !v)
  }

  protected get estimateConfig() {
    return this.fieldConfigs?.estimate
  }

  protected get estimateTime() {
    if (this.estimate.time_cost || this.estimate.time_cost === 0) {
      const hidden = this.estimateConfig?.time?.includes('hidden')
      return hidden ? '' : this.$currency(Number(this.estimate.time_cost.toFixed(2)))
    }
  }

  protected get estimateTimeCaption() {
    const time = this.estimateTime ? this.$isAvailable('booking.estimated_caption_time') : false
    return time ? `* ${time}` : ''
  }

  protected get estimateDistance() {
    const hidden = this.estimateConfig?.distance?.includes('hidden')
    if (this.estimate.distance_cost) {
      return hidden ? '' : this.$currency(Number(this.estimate.distance_cost.toFixed(2)))
    }
  }

  protected get estimateDistanceCaption() {
    const time = this.estimateTime ? this.estimateTimeCaption ? '*' : '' : ''
    const dist = this.estimateDistance ? this.$isAvailable('booking.estimated_caption_distance') : false
    return dist ? `${time}* ${dist}` : ''
  }

  protected get estimateTotal() {
    const total = this.estimate.time_cost + this.estimate.distance_cost
    const hidden = this.estimateConfig?.total?.includes('hidden')
    if (total) {
      return hidden ? '' : this.$currency(Number(total.toFixed(2)))
    }
  }

  protected get estimateTotalCaption() {
    const time = this.estimateTime ? this.estimateTimeCaption ? '*' : '' : ''
    const dist = this.estimateDistance ? this.estimateDistanceCaption ? '*' : '' : ''
    const total = this.estimateTotal ? this.$isAvailable('booking.estimated_caption_total') : false
    return total ? `${time}${dist}* ${total}` : ''
  }

  protected calculateDistance(start: string, end: string) {
    if (this.estimateConfig?.distance?.includes('set0')) {
      return 0
    } else {
      const startM = moment(start, 'YYYY-MM-DD HH:mm:ss')
      const endM = moment(end, 'YYYY-MM-DD HH:mm:ss')
      return Math.abs(Math.floor(startM.diff(endM, 'hours')) * 7)
    }
  }

  @Watch('requestForEstimate', {deep: true, immediate: true})
  protected getEstimate(obj: BookTimeSelectRequest | null) {
    if (obj && obj.start !== obj.end) {
      if (!obj.start || !obj.end) {
        this.estimate.time_cost = 0
        this.estimate.distance_cost = 0
        return
      }

      this.estimateLoader = true

      if (!this.extend) {
        const estimate: EstimateRequest = {
          start: obj.start,
          end: obj.end,
          plate: this.vehicleSlot.vehicle.plate,
          distance: this.calculateDistance(obj.start, obj.end),
        }
        this.bookEstimate(estimate).then((resp: Estimate) => {
          this.estimate = resp
        }).finally(() => {
          this.estimateLoader = false
        })
      } else {

        const xtd = this.extend
        const returnResult = (ex: Estimate, og: Estimate) => {
          this.estimate = {
            distance_cost: ex.distance_cost - og.distance_cost,
            time_cost: ex.time_cost - og.time_cost,
          }
          this.estimateLoader = false
        }
        this.bookEstimate({
          start: xtd.start,
          end: obj.end,
          plate: this.vehicleSlot.vehicle.plate,
          distance: this.calculateDistance(xtd.start, obj.end),
        }).then((ex) => {
          if (this.extendEstimate) {
            returnResult(ex, this.extendEstimate)
          } else {
            this.bookEstimate({
              start: xtd.start,
              end: xtd.end,
              plate: this.vehicleSlot.vehicle.plate,
              distance: this.calculateDistance(xtd.start, xtd.end),
            }).then((og) => {
              this.$set(this, 'extendEstimate', og)
              returnResult(ex, og)
            })
          }
        }).catch(() => {
          this.estimateLoader = false
        })
      }
    }
  }
}
