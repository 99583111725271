




















import {Component, Prop} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {Action, State} from 'vuex-class'
import {TopbarButton} from '@/store/modules/topbar'
import Utils from '@/utils'
import {
  ReservationPaginatedRequest,
  ReservationPaginatedResponse,
  ReservationResponse,
} from '@/lib/kepler/interfaces'

import HistoryBookingDetail from './HistoryBookingDetail.vue'
import InfiniteScrollHandler, {infiniteHandlerState} from '../../lib/InfiniteScrollHandler'
import ReservationHelper from '@/lib/reservation'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    CardReservation: Utils.loadComponent('entities/reservation/CardReservation'),
    InfiniteLoading: Utils.loadComponent('infiniteScroll/InfiniteLoading'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    EmptyList: Utils.loadComponent('EmptyList'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
  },
  mixins: [InfiniteScrollHandler],
})
export default class FullListHistory extends mixins(InfiniteScrollHandler) {
  @State((state) => state.booking.reservations) public reservations!: ReservationPaginatedResponse
  @Action('reservationHistory') public reservationHistory!: (req?: ReservationPaginatedRequest) => Promise<ReservationPaginatedResponse>
  @Action('setActionButtons') public setActionButtons!: (buttons: TopbarButton[]) => void

  @Prop({
    type: Number,
    default: Infinity,
  }) public max!: number
  @Prop({
    type: Boolean,
    default: true,
  }) public reloadButton!: boolean
  @Prop({
    type: Function || null,
    default: null,
  }) public action!: () => void

  protected loading: boolean = false

  protected infiniteHandler(state: infiniteHandlerState) {
    if (this.reservations.current_page >= 0) {
      this.handler(state, this.max, this.reservations, this.reservationHistory)
    }
  }

  protected openDetail(reservation: ReservationResponse) {
    if (this.action !== null) {
      return this.action
    }
    const current: boolean = ReservationHelper.isCurrent(reservation)
    const late: boolean = ReservationHelper.isLate(reservation)

    if (current || late) {
      this.$router.push({name: 'reservation', params: {id: reservation.id}})
    } else {
      this.$popup.open(HistoryBookingDetail, {
        props: {resNum: reservation.number},
        title: this.$t('booking.reservation', {number: reservation.number}),
      })
    }
  }
}
