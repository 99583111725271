






















import {Component, Prop, Vue} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    Button: Utils.loadComponent('Button'),
    Icon: Utils.loadComponent('proxy/Icon'),
    EmptyList: Utils.loadComponent('EmptyList'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
  },
  name: 'FilteredListWithAddButton',
})
export default class FilteredListWithAddButton extends Vue {
  @Prop({type: [Function, Boolean]}) public addCallback?: () => void
  @Prop({type: String, default: 'mdi-plus'}) public icon!: string
  @Prop({type: Boolean, default: false}) public empty!: boolean
  @Prop({type: Boolean, default: false}) public hideFilters!: boolean
  @Prop({type: Boolean, default: false}) public filterHasResults!: boolean
  @Prop({type: Boolean, default: false}) public loading!: boolean
}
