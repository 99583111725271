






































import {Component, PropSync} from 'vue-property-decorator'
import Utils from '@/utils'
import {mixins} from 'vue-class-component'
import {FiltersLogic} from '@/components/filters/FiltersLogic'
import {VBtn, VSlider} from 'vuetify/lib'
import {Action, Getter, State} from 'vuex-class'
import {FilterButton, FilterStructure} from '@/store/modules/filters'
import ServiceMesh from '@/lib/serviceMesh'
import {SearchR, SearchReq} from '@/store/modules/map'

@Component({
  name: 'MapFilters',
  components: {
    VBtn,
    VSlider,
    Card: Utils.loadComponent('proxy/Card/Card'),
    Dialog: Utils.loadComponent('proxy/Dialog'),
    Icon: Utils.loadComponent('proxy/Icon'),
    RangeFilter: Utils.loadComponent('filters/RangeFilter'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Divider: Utils.loadComponent('proxy/Divider'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    FiltersLayout: Utils.loadComponent('filters/FiltersLayout'),
    VehicleIcon: Utils.loadComponent('VehicleIcon'),
    GradientCard: Utils.loadComponent('GradientCard'),
    DateTimeRangeField: Utils.loadComponent('fields/DateTimeRangeField'),
  },
})
export default class Filters extends mixins<FiltersLogic>(FiltersLogic) {
  @Getter('getFilters') public readonly filters!: FilterStructure | null
  @State((state) => state.filters.enabledFilters) public readonly enabledButtons!: string[]
  @State((state) => state.mapItems.itemsRequest) public readonly itemsRequest!: SearchReq
  @PropSync('showFilters', {type: Boolean}) public show!: boolean

  @Action('setMapItems') protected setMapItems!: (r: SearchR) => void
  @Action('toggleFilterButton') protected toggleFilterButton!: (id: string) => void
  @Action('modifyMapItemsRequest') protected modifyDates!: (p: { start: string, end: string }) => void

  protected get dates() {
    return {
      start: this.itemsRequest.start || '',
      end: this.itemsRequest.end || '',
    }
  }

  protected set dates({end, start}: { start: string | null, end: string | null }) {
    if (start && end) {
      this.modifyDates({start, end})
    }
  }

  protected get hasRangeFilter() {
    if (this.filters) {
      return this.filters?.meta?.range_selector
    }
    return null
  }

  protected get hasTimePicker() {
    if (this.filters) {
      return this.filters?.meta?.range_selector
    }
    return null
  }

  protected buttonAction(item: FilterButton) {
    this.toggleFilterButton(item.id)
  }

  protected isVehicle(v: string) {
    return ServiceMesh.getBmvt().includes(v)
  }

  protected hide() {
    this.show = false
  }

  protected getvehicleType(name: string) {
    return ServiceMesh.splitBMVT(name)?.[1] || null
  }
}
