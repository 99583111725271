










import {Component} from 'vue-property-decorator'
import BaseFlow from '@/views/Flows/BaseFlow.vue'
import IsLogged from '@/components/IsLogged.vue'
import {Action} from 'vuex-class'

@Component({components: {IsLogged}})
export default class FlowBookingRequest extends BaseFlow {
  @Action('flushAndReloadBookingRequestHistory') public reload!: () => Promise<any>
  public exitBack() {
    // this.getPersistent()
    if (this.$route.name !== 'activities') {
      this.$router.go(-1)
    }
    this.$popup.close()
  }

  public exitForward() {
    if (this.$route.name !== 'home') {
      this.$router.push({name: 'home'})
    }
    this.$nextTick(this.reload)
    this.$popup.close()
  }

  public getName(): string {
    return 'driver_booking_request'
  }

  protected beforeDestroy() {
    this.purgeFlow('driver_booking_request')
  }
}
