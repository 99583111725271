
























import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import ConfirmDialog from '../views/ConfirmDialog.vue'
import ErrorView from './Error.vue'
import Utils from '@/utils'
import {TopupResponse, Wallet} from '@/lib/kepler/interfaces'
import {DialogState} from '@/store/modules/dialogs'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
  },
  name: 'AddCredit',
})
export default class AddCredit extends Vue {
  @State((state) => state.configuration.appConfig?.fields_configuration?.topup_sizes) public topupState?: string
  @State('dialogState') public dialogState!: typeof DialogState

  @Getter('defaultWallet') public defaultWallet?: Wallet

  @Action('buyTopUpCredit') public buyTopUpCredit!: (amount: number) => Promise<TopupResponse>
  @Action('getWallets') public getWallets!: () => Promise<Wallet[]>
  @Action('getProfile') public getProfile!: () => Promise<void>

  @Prop({default: true}) private emitConfirm!: boolean
  @Prop() private confirmText!: string
  @Prop() private cancelText!: string
  @Prop({default: true}) private showCloseButton!: boolean

  private topUpSize: string = ''
  private isLoading: boolean = false

  protected get confirmButton() {
    return this.confirmText ? this.confirmText : this.$t('action.proceed')
  }

  protected get topUpSizes() {
    return this.topupState?.split(',').filter((t) => !!Number(t)) || []
  }

  protected created() {
    if (!this.topupState) {
      this.$dialog.close()
    }
  }

  protected buyTopUp() {
    this.isLoading = true
    this.buyTopUpCredit(Number(this.topUpSize)).then((r: any) => {
        this.getWallets().finally(() => {
          if (r.status === 'PAID') {
            this.getProfile().finally(() => {
              this.isLoading = false
              this.$dialog.close(0)
              this.$dialog.open(ConfirmDialog, {
                props: {
                  code: '',
                  title: this.$t('profile.wallet.topUp.confirmation.title'),
                  subtitle: this.$t('profile.wallet.topUp.confirmation.subtitle'),
                  confirmText: this.$t('profile.wallet.topUp.confirmation.button'),
                  imageState: 'icons/Card-big.svg',
                  singleAction: true,
                },
              })
            })
          }
        })
      },
    ).catch((e: any) => {
      this.isLoading = false
      this.$dialog.close()
      this.$dialog.open(ErrorView, {
        props: {
          code: '',
          title: e.response.data.result,
          subtitle: '',
          singleAction: true,
        },
      })
    })
  }
}
