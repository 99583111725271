
















import {Vue, Component, Prop} from 'vue-property-decorator'
import {ProfileState} from '@/store/modules/profile'
import {Driver, SubscriptionPlan} from '@/lib/kepler/interfaces'
import Utils from '../utils'
import {Action, Getter, State} from 'vuex-class'
import ConfirmDialogCallback from './ConfirmDialogCallback.vue'

@Component({
  components: {
    Divider: Utils.loadComponent('proxy/Divider'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    Icon: Utils.loadComponent('proxy/Icon'),
    ProfileWithImage: Utils.loadComponent('ProfileWithImage'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    StatusLabel: Utils.loadComponent('StatusLabel'),
  },
  name: 'MyDriversProfile',
})

export default class MyDriversProfile extends Vue {
  @State('profile') public profileState!: ProfileState
  @Getter('driversByPlan') public driversByPlan!: Record<string, string[]>
  @Getter('defaultPlan') public defaultPlan!: SubscriptionPlan | null
  @Getter('onlyDefaultPlan') public onlyDefaultPlan!: boolean
  @Action('getDrivers') public getDrivers!: () => void
  @Action('suspendDriver') public suspendDriver!: (d: Driver) => Promise<void>
  @Action('reactivateDriver') public reactivateDriver!: (d: Driver) => Promise<void>

  @Prop() public driver!: Driver

  protected loading: boolean = false

  protected get driverStatus(): boolean {
    return this.driver ? this.driver.status === 'ACTIVE' || false : false
  }

  protected get driverHasPlan() {
    const defaultId = this.defaultPlan?.id
    // const subscriptions = this.profileState.subscriptions
    // const onlyDefaultSubscription = subscriptions.length === 1 && subscriptions[0].plan.default
    return Object.entries(this.driversByPlan).find(([k, v]) =>
      // if not default plan has driver
      v.includes(this.driver.id) && (k !== defaultId
        // uncomment below if you want to hide the button when there is only the default subs available
        // || onlyDefaultSubscription
      ))
  }

  protected suspend() {
    this.loading = true
    this.suspendDriver(this.driver).then(() => {
      this.$dialog.open(ConfirmDialogCallback, {
        props: {
          imageState: 'success.svg',
          code: '',
          title: this.$t('profile.suspended_confirm'),
          subtitle: '',
          confirmCallback: () => {
            this.$dialog.close()
          },
        }, hideTopbar: false,
      })
      this.loading = false
    })
  }

  protected reactivate() {
    this.loading = true
    this.reactivateDriver(this.driver).then(() => {
      this.$dialog.open(ConfirmDialogCallback, {
        props: {
          imageState: 'success.svg',
          code: '',
          title: this.$t('profile.reactivated_confirm'),
          subtitle: '',
          confirmCallback: () => {
            this.$dialog.close()
          },
        },
        hideTopbar: false,
      })
      this.loading = false
    })
  }

  protected goToSubs() {
    this.$dialog.close()
    this.$router.push({name: 'my subscriptions'})
  }

}
