import popupState from '@/store/modules/popups'
import dialogState from '@/store/modules/dialogs'
import configuration from '@/store/modules/configuration'
import profile from '@/store/modules/profile'
import wallet from '@/store/modules/wallet'
import subscriptions from '@/store/modules/subscriptions'
import clientTypes from '@/store/modules/clientTypes'
import licenseTypes from '@/store/modules/licenseTypes'
import termOfServices from '@/store/modules/termOfServices'
import booking from '@/store/modules/booking'
import topbar from '@/store/modules/topbar'
import vehicle from '@/store/modules/vehicle'
import reportings from '@/store/modules/reportings'
import timestamps from '@/store/modules/timestamps'
import registration from '@/store/modules/registration'
import flowInputs from '@/store/modules/flowInputs'
import flowOutputs from '@/store/modules/flowOutputs'
import flowPersistent from '@/store/modules/flowPersistent'
import accounts from '@/store/modules/accounts'
import bookingRequests from '@/store/modules/bookingRequests'
import menu from '@/store/modules/menu'
import filters from '@/store/modules/filters'
import mapItems from '@/store/modules/map'
import notifications from '@/store/modules/notifications'

const modules = {
  popupState,
  dialogState,
  configuration,
  profile,
  wallet,
  subscriptions,
  clientTypes,
  licenseTypes,
  termOfServices,
  booking,
  topbar,
  vehicle,
  reportings,
  timestamps,
  registration,
  flowInputs,
  flowOutputs,
  flowPersistent,
  accounts,
  bookingRequests,
  menu,
  filters,
  mapItems,
  notifications,
}

export type StoreModules = { [P in keyof typeof modules]: typeof modules[P]['state'] }

export default modules
