import { render, staticRenderFns } from "./BookingRequestHistory.vue?vue&type=template&id=f8d8f1f2&lang=pug&"
import script from "./BookingRequestHistory.vue?vue&type=script&lang=ts&"
export * from "./BookingRequestHistory.vue?vue&type=script&lang=ts&"
import style0 from "./BookingRequestHistory.vue?vue&type=style&index=0&id=f8d8f1f2&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports