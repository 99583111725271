































import {Component} from 'vue-property-decorator'
import Utils from '@/utils'

import {ValidationProvider} from 'vee-validate'
import {VIcon, VImg, VLayout, VTextField} from 'vuetify/lib'
import RegistrationImageComponent from '@/components/registration/RegistrationImageComponent.vue'
import {Attachment, ProfileImageRequest, RegistrationImage} from '@/lib/kepler/interfaces'
import {Action} from 'vuex-class'

@Component({
  components: {
    ValidationProvider,
    VTextField,
    VLayout,
    VIcon,
    VImg,
    Btn: Utils.loadComponent('proxy/Btn'),
    CameraButtonCustom: Utils.loadComponent('uploader/CameraButtonCustom'),
  },
  name: 'SelfieRegistrationView',
})
export default class SelfieRegistrationView extends RegistrationImageComponent {
  declare public loading: boolean
  declare public result: string | null
  declare protected imageUploadResponse: RegistrationImage

  @Action('addProfilePicture') private addProfilePicture!: (payload: ProfileImageRequest) => Promise<string>

  protected created() {
    if (this.imageUploadResponse.sent) {
      this.imageUploadResponse.token = ''
      this.$emit('input', this.imageUploadResponse)
    }
  }

  protected gotUploadResponse(r: Attachment) {
    this.imageUploadResponse = Object.assign(new RegistrationImage(), {...r})
    this.loading = true
    this.$emit('input', this.imageUploadResponse)
    this.result = r.url
  }

  protected deleteAttachment() {
    this.imageUploadResponse = new RegistrationImage()
    this.loading = false
    this.result = null
    this.$emit('input', this.imageUploadResponse)
  }
}
