
































import {Vue, Component, Prop} from 'vue-property-decorator'
import {Getter, State} from 'vuex-class'
import Utils from '@/utils'
import {FieldConfigs} from '@/lib/kepler/interfaces'
import FlowDialog from '@/views/Flows/FlowDialog.vue'
import FlowAutoload from '@/views/Flows/FlowAutoload.vue'
import {MenuButton, MenuState} from '@/store/modules/menu'
import {FlowInputsState} from '@/store/modules/flowInputs'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Img: Utils.loadComponent('proxy/Image'),
    BottomNav: Utils.loadComponent('proxy/BottomNav'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Btn: Utils.loadComponent('proxy/Btn'),
  },
})
export default class HomeMenu extends Vue {
  @State('flowInputs') public flowInputs!: FlowInputsState

  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs
  @Getter('isLogged') public isLogged!: boolean
  @Getter('popupIsOpen') public popupIsOpen!: number
  @Getter('menu') public homeMenu!: MenuState

  @Prop({type: Boolean, default: false}) public absolute!: boolean

  protected get buttonClass() {
    let cls = ''
    if (this.homeMenu.name === 'labeled') {
      cls += `text-button ${this.homeMenu.obj?.class}`
    } else if (this.homeMenu.name === 'logo') {
      cls += `logo-button ${this.homeMenu.obj?.class}`
    }
    return cls
  }

  protected get active() {
    return this.homeMenu.buttons?.find((b) => {
      return b.path === this.$route.path || b.routeName === this.$route.name ? 'v-btn--active' : false
    }) ? this.$route.name : undefined
  }

  protected get bottomHeight() {
    // beware: shitty & hacky!
    const style = getComputedStyle(document.documentElement)
    const sab = style.getPropertyValue('--sab') ?? null
    if (sab) {
      return parseInt(sab, 10) + 56 + 'px'
    }
    return '56px'
  }

  protected buttonRoute(b: MenuButton) {
    // TODO: meh but also eh.
    /* tslint:disable: cyclomatic-complexity */
    // noinspection IfStatementWithTooManyBranchesJS
    if (b.path) {
      if (this.$route.fullPath !== b.path) {
        this.$router.push(b.path)
      }
    } else if (b.routeName) {
      if (b.routeName !== this.$route.name) {
        this.$router.push({name: b.routeName})
      }
    } else if (b.flow) {
      const flowName = b.flow
      if (this.flowInputs[flowName]) {
        if (this.flowInputs[flowName].dialog) {
          this.$dialog.open(FlowDialog, {props: {flowName}, title: flowName})
        } else {
          this.$popup.open(FlowAutoload, {props: {flowName}, hideTopbar: true})
        }
      }
    } else if (b.url) {
      cordova.InAppBrowser.open(b.url, '_system')
    }
  }

}
