









































import {Component, Prop} from 'vue-property-decorator'
import {SubscriptionPlan, Rate, RateInfo} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import {PlanColorMixin} from '@/lib/vuetify/VuetifyColorHelper'
import {mixins} from 'vue-class-component'
import {Getter} from 'vuex-class'

@Component({
  components: {
    VehicleIcon: Utils.loadComponent('VehicleIcon'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    VehicleFares: Utils.loadComponent('entities/vehicle/VehicleFares'),
    GradientCard: Utils.loadComponent('GradientCard'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Img: Utils.loadComponent('proxy/Image'),
  },
  mixins: [PlanColorMixin],
})
export default class PlanDetailRates extends mixins<PlanColorMixin>(PlanColorMixin) {
  @Getter('monoMode') public monoMode!: boolean

  @Prop() public plan!: SubscriptionPlan
  @Prop({
    type: String,
  }) public mode!: string
  @Prop({
    type: Array,
    default: () => [],
  }) public rates!: Rate[]

  public accordionIndex: number | null = null

  public onlyOne: boolean = false

  public get planColors() {
    return this.getPlanColors(this.plan.color)
  }

  public get textGradient() {
    const c = this.planColors
    const isLight = this.getPlanIsLight(this.plan)
    const bgIsLight = !this.$vuetify.dark
    if (isLight && bgIsLight || !isLight && !bgIsLight) {
      return null
    }

    return {
      color: c.top ? c.top : 'inherit',
      background: c.top && c.bottom ? `linear-gradient(45deg, ${c.top}, ${c.bottom})` : 'currentColor',
    }
  }

  public get parsedRates() {
    const obj: Record<string, any> = {}
    this.rates.forEach((r) => {
      const timeRate = this.rateInfo(r, 'time_rate') as string | null
      const [timeCost, timeCycle] = timeRate?.split('|') ?? [null, null]
      const [distanceCost, distanceCycle] = r.info?.distance_rate?.split('|') ?? [null, null]
      obj[r.id] = {
        time: {
          cost: timeCost !== null ? this.$currency(timeCost) : null,
          cycle: timeCycle !== null ? this.$parseMinutes(timeCycle) : null,
        },
        distance: {
          cost: distanceCost !== null ? this.$currency(distanceCost) : null,
          cycle: distanceCycle !== null ? this.parseDistance(distanceCycle) : null,
        },
      }
    })
    return obj
  }

  public bookingModes(rate?: Rate) {
    let bm = ''
    if (!rate && this.plan) {
      for (const r of this.plan.rates) {
        if (!bm.includes(r.booking_mode)) {
          bm += bm.length > 0 ? ',' + r.booking_mode : r.booking_mode
        }
      }
    } else if (rate) {
      bm = rate.booking_mode
    }
    return bm.split(',')
  }

  public created() {
    if (this.rates.length === 1) {
      this.accordionIndex = 0
      this.onlyOne = true
    }
  }

  public parseDistance(val: string) {
    return this.$distance(val && Number(val) !== 1 ? val : undefined)
  }

  public rateInfo(rate: Rate, prop?: keyof RateInfo) {
    return rate.info ? prop ? rate.info[prop] : rate.info : null
  }
}
