


























import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {FlowInputStep} from '@/lib/kepler/interfaces'
import {ValidationProvider} from 'vee-validate'
import {EventBus} from '@/main'
import Utils from '@/utils'

interface RadioButtonItem {
  value: string | number,
  text: string,
  description: string,
  color?: string,
  icon?: string,
}

@Component({
  components: {
    ValidationProvider,
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
  name: 'RadioButtons',
})
export default class RadioButtons extends Vue {
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules: any

  @Prop({
    type: String || Number,
    default: null,
  }) public readonly value!: string | number | null

  @Prop({
    type: Array,
    default: () => [],
  }) public readonly items!: RadioButtonItem[] | any[]

  @Prop() public step?: FlowInputStep
  @Prop({
    type: String,
    required: false,
  }) public name?: string
  @Prop({
    type: String,
    required: false,
  }) public description?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public disabled?: boolean

  public innerValue: string | number | null = null
  public loading: boolean = false

  @Watch('innerValue')
  public onInnerValueChange(newVal: any) {
    if (newVal !== this.value) {
      this.$emit('input', newVal)
    }
  }

  @Watch('value')
  public onValueChange(newVal: string) {
    this.innerValue = newVal
  }

  public setValue(value: string) {
    this.innerValue = value
    if (this.step?.disable_forward && !this.disabled) {
      this.disabled = true
      this.loading = true
      EventBus.$emit('flowStepForward')
    }
  }

  protected created() {
    if (this.value) {
      this.innerValue = this.value
      this.$emit('input', this.value)
    }
  }
}
