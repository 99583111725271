import Vue from 'vue'

export default class Utils {
  public static loadView(name: string) {
    return () => import(/* webpackChunkName: "views-[request]" */ `@/views/${name}.vue`)
  }

  public static loadComponent(name: string) {
    return () => import(/* webpackChunkName: "components-[request]" */ `@/components/${name}.vue`)
  }

  // to get the property foo.bar.baz from a Vuex state object you would call getProp(state, ['foo', 'bar', 'baz']).
  // If any of the properties in the chain don't exist the function returns undefined
  public static getProp(obj: Record<string, any>, props: string[]): any {
    const prop = props.shift()
    if (prop === undefined) {
      return undefined
    }
    if (!obj[prop] || !props.length) {
      return obj[prop]
    }
    return Utils.getProp(obj[prop], props)
  }

  // to set a new property foo.bar.baz = true on a Vuex state object you would call setProp(state, ['foo', 'bar', 'baz'], true).
  // The function creates any nested properties that don't already exist.
  public static setProp(obj: Record<string, any>, props: string[], value: any) {
    const prop = props.shift()
    if (prop) {
      if (!obj[prop]) {
        Vue.set(obj, prop, {})
      }
      if (!props.length) {
        if (value && typeof value === 'object' && !Array.isArray(value) && value instanceof Object) { // check if object
          Vue.set(obj, prop, {...obj[prop], ...value})
        } else {
          Vue.set(obj, prop, value)
        }
        return
      }
      Utils.setProp(obj[prop], props, value)
    } else {
      throw new Error('empty props')
    }
  }

  public static setProps(obj: Record<string, any>, target: Record<string, any>) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        Vue.set(target, prop, obj[prop])
      }
    }
  }

  public static deleteProp(obj: { [i: string]: { [v: string]: any } }, props: any[] | string) {
    const properProp = (): string => {
      if (Array.isArray(props)) {
        return props.shift()
      } else {
        return props
      }
    }

    const prop = properProp()

    if (!obj[prop]) {
      return
    }
    if (!props.length) {
      Vue.delete(obj, prop)
      return
    }
    Utils.deleteProp(obj[prop], props)
  }

  public static flushObject(obj: { [i: string]: { [k: string]: any } }) {
    for (const prop of Object.keys(obj)) {
      Utils.deleteProp(obj, prop)
    }
  }

  public static flushArray(arr: any[]) {
    return arr.length ? arr.splice(0) : arr
  }

  // https://javascript.plainenglish.io/deep-clone-an-object-and-preserve-its-type-with-typescript-d488c35e5574
  public static deepCopy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map((item) => this.deepCopy(item))
      : source instanceof Date
        ? new Date(source.getTime())
        : source && typeof source === 'object'
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
            Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!)
            o[prop] = this.deepCopy((source as { [key: string]: any })[prop])
            return o
          }, Object.create(Object.getPrototypeOf(source)))
          : source as T
  }

  public static freezeCopy<T>(source: T): T {
    return Object.freeze(structuredClone(source))
  }

  public static hash = (s: string) => {
    // tslint:disable:no-bitwise
    let i = 0
    let h = 9
    while (i < s.length) {
      h = Math.imul(h ^ s.charCodeAt(i++), 9 ** 9)
    }
    return Math.abs(h ^ h >>> 9)
  }

  public static mergeUnique = <V>(prev: V[], values: V | V[] | null = []): (V)[] => {
    if (!values) {
      return prev
    } else if (Array.isArray(values)) {
      const p: Set<V> = new Set(prev)
      values.forEach((v: V) => p.add(v))
      return Array.from(p)
    } else {
      return Array.from(new Set(prev).add(values))
    }
  }
}
