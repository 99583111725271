


















import {Component, Prop, Vue} from 'vue-property-decorator'
import Utils from '@/utils'
import {State} from 'vuex-class'

import {BookingMode, UserMesh, VehicleCategory} from '@/lib/kepler/interfaces'
import ServiceMesh from '@/lib/serviceMesh'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Button: Utils.loadComponent('Button'),
  },
})

export default class CategorySelector extends Vue {
  @State((state) => state.booking.categories) protected categoriesState!: VehicleCategory[]
  @State((state) => state.profile.userMesh) protected userMesh!: UserMesh[]

  @Prop({type: Array, default: []}) protected value!: string[]
  @Prop({type: String, required: false}) protected type?: string
  @Prop({type: String, required: false}) protected mode?: keyof typeof BookingMode

  protected selected: string[] = []
  protected color: string | null = null

  protected get filteredCategories() {
    return this.categoriesState.filter((c) => c.type?.toUpperCase() === this.type?.toUpperCase())
  }

  protected get onlyOne() {
    return this.filteredCategories.length === 1
  }

  protected get title() {
    if (!this.selected.length) {
      return this.$t('vehicle.search.all')
    } else {
      const filtered = this.filteredCategories.filter((c) => this.selected.includes(c.id))
      const extra = filtered.length - 1
      return `${filtered[0].name}${extra ? ' +' + extra : ''}`
    }
  }

  protected toggle(id: string) {
    const s = new Set(this.selected)
    if (s.has(id)) {
      s.delete(id)
    } else {
      s.add(id)
    }
    this.selected = Array.from(s)
    this.$emit('input', this.selected.length ? this.selected : null)
  }

  protected categoryString(category: VehicleCategory) {
    const type = category.type.toLowerCase()
    let name = category.name.toLowerCase()
    if (!this.type) {
      name = (name.includes(type) ? '' : type + ' - ') + name
    }
    return name
  }

  protected created() {
    if (this.mode && this.type) {
      const bmvt = (this.mode + this.type).toUpperCase()
      this.color = ServiceMesh.colors[bmvt]
    }
  }
}
