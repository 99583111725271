





import {Vue, Component, VModel, Watch} from 'vue-property-decorator'

@Component({})
export default class ClickOutside extends Vue {
  @VModel({type: Boolean}) private active!: boolean

  @Watch('active', {immediate: true})
  private onShow(val: boolean) {
    if (val) {
      ['pointerdown'].forEach((evt) => {
        this.$root.$el.addEventListener(evt, this.clickOutside, {passive: true})
      })
    }
  }

  private clickOutside(e: Event) {
    if (e.target instanceof Element) {
      const dialog = !!e.target.closest('#app>.v-dialog__content')
      const menu = !!e.target.closest('#app>.v-menu__content')
      if (!(this.$el === e.target || this.$el.contains(e.target) || menu || dialog)) {
        ['pointerdown'].forEach(evt => this.$root.$el.removeEventListener(evt, this.clickOutside))
        this.active = false
      }
    }
  }
}
