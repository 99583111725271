















































































import {Component, Prop} from 'vue-property-decorator'
import {Subscription} from '@/lib/kepler/interfaces'
import {Action} from 'vuex-class'
import moment from 'moment'
import Utils from '@/utils'

import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import GradientCard from '../GradientCard.vue'
import PlanDetailDialog from '@/views/PlanDetailDialog.vue'
import SubscriptionAssignDialog from '@/components/SubscriptionAssignDialog.vue'

@Component({
  components: {
    GradientCard,
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Button: Utils.loadComponent('Button'),
    Icon: Utils.loadComponent('proxy/Icon'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Ribbon: Utils.loadComponent('Ribbon'),
  },
})
export default class CardSubscription extends GradientCard {
  @Action('autorenew') public autorenew: any
  @Prop({}) protected subscription!: Subscription
  @Prop({
    type: Boolean,
    default: true,
  }) protected arrow!: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) protected defaultPlan!: boolean

  protected autoRenew: boolean | null = null

  protected get activeFrom() {
    return this.subscription.start ? moment(this.subscription.start).format('L') : '--'
  }

  protected get expiresIn() {
    const now = moment()
    const end = moment.unix(this.subscription.end_timestamp)
    const diff = moment.duration(end.diff(now))
    return moment.duration(diff).humanize()
  }

  protected get nextBillingIn() {
    const now = moment()
    const next = moment.unix(this.subscription.next_due_date_timestamp)
    const diff = moment.duration(next.diff(now))
    const h = moment.duration(diff).humanize()
    const d = next.format('l')
    return `${h} (${d})`
  }

  protected get cycle() {
    return moment.duration(this.subscription.plan.billing_cycle, 'days').humanize()
  }

  protected get isActive() {
    if (this.defaultPlan) {
      return true
    }
    const now = moment()
    const end = moment.unix(this.subscription.end_timestamp)
    return now.isBefore(end)
  }

  protected get expiredFrom() {
    return this.subscription.end ? moment(this.subscription.end).format('L') : '--'
  }

  protected get autorenewable() {
    return !this.defaultPlan && (this.subscription.plan.renewable || this.subscription.plan.renewable === undefined)
  }

  protected get lastCycleWithoutRenewal() {
    const isLast = this.subscription.next_due_date_timestamp >= this.subscription.end_timestamp
    return isLast && !this.subscription.auto_renew
  }

  protected mounted() {
    this.autoRenew = this.subscription.auto_renew
  }

  protected toggleAutorenew() {
    const oldStatus = this.autoRenew
    const status = !oldStatus

    if (oldStatus !== null) {
      if (!status) {
        this.$dialog.open(ConfirmDialogCallback, {props: {
            code: '',
            title: this.$t('subscription.autorenew.deactivate.title'),
            subtitle: this.$t('subscription.autorenew.deactivate.subtitle'),
            confirmText: this.$t('action.proceed'),
            cancelText: this.$t('action.cancel'),
            imageState: this.$t('subscription.autorenew.image_url'),
            singleAction: false,
            emitConfirm: true,
            showCloseButton: false,
            confirmCallback: () => {
              this.autorenew({subscription_id: this.subscription.id, status: false}).then(() => {
                this.autoRenew = status
              })
            },
            cancelCallback: () => {
              this.autoRenew = oldStatus
            },
          }})
      } else {
        this.autorenew({subscription_id: this.subscription.id, status}).then(() => {
          this.autoRenew = status
        })
      }
    }
  }

  protected openPlanDetail(plan: {
    name?: string,
    id: string,
  }) {
    this.$dialog.open(PlanDetailDialog, {props: {plan, hideCta: true}})
  }

  protected assignToDrivers(subscription: Subscription) {
    this.$dialog.open(SubscriptionAssignDialog, {props: {subscription}})
  }
}
