
























import {Vue, Component, Inject, Prop, Provide, Watch} from 'vue-property-decorator'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import Utils from '@/utils'

@Component({
  components: {
    Btn: Utils.loadComponent('proxy/Btn'),
  },
})
export default class Button extends Vue {
  @Prop({}) public link?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public stroked?: boolean
  @Prop({}) public textColor?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public block?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public disabled?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public loading?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public icon?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public round?: boolean
  @Prop({
    type: Boolean,
  }) public light?: boolean
  @Prop({
    type: Boolean,
  }) public dark?: boolean
  @Prop({
    type: Boolean,
  }) public bottom?: boolean
  @Prop({
    type: Boolean,
  }) public top?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public flat?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public small?: boolean
  @Prop({
    default: 'buttonDefaultColor',
  }) public color!: string

  @Provide('theme') protected themeableProvide = {
    isDark: false,
  }

  @Inject() protected readonly theme!: { isDark: boolean }

  protected styleObj?: object = {}

  protected get colorName() {
    if (this.disabled) {
      return ''
    }

    if (this.color.startsWith('#')) {
      this.styleObj = {
        'background-color': this.color,
        'border-color': this.color,
        'color': this.textColor,
      }
    } else {
      return VuetifyColorHelper.color(this.color)
    }
  }

  protected get isDark(): boolean {
    const override = this.$branding.theme.buttonDefaultColorOverride
    // noinspection IfStatementWithTooManyBranchesJS
    if (this.disabled) {
      // hight transparency, consider bg darkness
      return this.theme.isDark
    } else if (this.dark === true) {
      // explicitly dark
      return true
    } else if (this.light === true) {
      // explicitly light
      return false
    } else if (override && (this.color === 'buttonDefault' || !this.color)) {
      // overridden color contrast
      return VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(override))
    } else if (this.color) {
      // color contrast
      return !VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(this.color))
    } else {
      // inherit from parent, or default false if there is none
      return this.theme.isDark
    }
  }

  @Watch('isDark')
  protected handleDark(newVal: boolean, oldVal: boolean) {
    if (newVal !== oldVal) {
      this.themeableProvide.isDark = this.isDark
    }
  }
}
