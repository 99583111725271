






















import {Vue, Component} from 'vue-property-decorator'
import {State} from 'vuex-class'
import {ProfileState} from '@/store/modules/profile'
import Utils from '../utils'
import {Client, Driver} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    StatusLabel: Utils.loadComponent('StatusLabel'),
    Chip: Utils.loadComponent('proxy/Chip'),
  },
  name: 'ClientDriverStatus',
})
export default class ClientDriverStatus extends Vue {
  @State('profile') public profileState!: ProfileState
  @State((state) => state.profile.client) public readonly client!: Client
  @State((state) => state.profile.driver) public readonly driver!: Driver

  public get driverProblems() {
    return !this.status(this.driver.status)
  }

  public get clientProblems() {
    return !this.status(this.client.status)
  }

  protected status(status?: string): boolean | null {
    const red: string[] = [
      'CANCELED',
      'SUSPENDED',
      'REFUSED',
    ]
    if (!status || status === 'ACTIVE' || status === 'APPROVED') {
      return true
    } else if (red.includes(status)) {
      return false
    }
    return null
  }

  protected description(status: string, type: string) {
    if (status) {
      return this.$t(`status.${type}.${status.replace(/ /g, '_')}`)
    }
  }

  protected statusColor(status: boolean | null): string {
    if (status === null) {
      return 'warning'
    }
    return status ? 'success' : 'error'
  }

}
