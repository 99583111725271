










































import {Component, Prop, Watch} from 'vue-property-decorator'
import {Action, Getter} from 'vuex-class'
import BaseFlow from '@/views/Flows/BaseFlow.vue'
import Utils from '@/utils'
import {ValidationObserver} from 'vee-validate'
import {VBtn, VLayout, VProgressCircular, VProgressLinear} from 'vuetify/lib'
import Validations from '@/lib/Validations'
import moment from 'moment'
import {LoginResponse} from '@/lib/kepler/interfaces'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import {AxiosError} from 'axios'
import AlreadyHasAccount from '@/views/AlreadyHasAccount.vue'
import ErrorView from '@/views/Error.vue'
import {EventBus} from '@/main'
import {debounce} from '@/lib/Debounce'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import smoothReflow from 'vue-smooth-reflow'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    Flow: Utils.loadComponent('flow/Flow'),
    VProgressLinear,
    VBtn,
    VProgressCircular,
    ValidationObserver,
    VLayout,
    Sheet: Utils.loadComponent('proxy/Sheet'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    FlowStep: Utils.loadComponent('flow/FlowStep'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  mixins: [smoothReflow],
})
export default class FlowDialog extends BaseFlow {
  @Action('updateProfilePicture') public updateProfilePicture!: (url?: string) => string

  @Getter('autoOpenQueue') public autoOpenQueue!: string[]

  @Prop({
    type: String,
  }) public flowName!: string
  public rules: any = Validations.rules
  public startMoment: moment.Moment = moment()
  public step = 0

  // shit
  public loading: boolean = false
  public locked: boolean = false
  public initialLoading: string | null = null

  public get currentFlow() {
    return this.flowInputs[this.flowName]
  }

  public get currentFlowOutput() {
    return this.flowOutputState[this.flowName]
  }

  public get currentStep() {
    return this.steps[this.step]
  }

  public get activeSteps() {
    return this.steps.filter((step) => {
      return !step.skip
    })
  }

  public get isLastStep() {
    return this.activeSteps.length ? this.activeSteps[this.activeSteps.length - 1].id === this.currentStep.id : null
  }

  public getName(): string {
    return this.flowName
  }

  public exitForward() {
    this.getFlows()
    this.getPersistent()
    this.purgeFlow(this.flowName)
    this.$dialog.close()
  }

  public exitBack() {
    this.$dialog.open(ConfirmDialogCallback, {
      props: {
        code: '',
        title: this.$isAvailable('flows.exit_back.title'),
        subtitle: this.$isAvailable('flows.exit_back.subtitle'),
        confirmText: 'Ok',
        imageState: '',
        confirmCallback: () => {
          this.getPersistent()
          this.$dialog.close()
        },
        cancelCallback: () => {
          return
        },
      },
    })
  }

  public progressColor() {
    const lastStep = this.step < this.activeSteps.length
    return !lastStep || this.loading ? 'grey' : 'accent'
  }

  @Watch('step')
  public onStepChange(step: number) {
    this.$router.replace({params: {step: step.toString()}}).catch((e) => e)
  }

  public stepper(val: number, validate?: () => Promise<boolean>) {
    const startId = this.currentStep.id
    const steppr = () => {
      const step = () => {
        let pointer = this.steps.findIndex((s) => s.id === startId)
        let i = val
        while (i !== 0) {
          const newPointer = pointer + Math.sign(i) // decrease/increase pointer by 1
          if (newPointer < 0) {
            this.exitBack()
            return
          } else if (newPointer === this.steps.length) {
            this.exitForward()
            return
          }
          if (!this.steps[newPointer].skip) {
            i = i - Math.sign(i)
            pointer = newPointer
          } else {
            pointer = newPointer
          }
        }
        this.step = Math.min(Math.max(pointer, 0), this.steps.length) // clamp value between 0 and steps length
      }

      if (val > 0) {
        this.evaluateOutput().then(step).finally(() => {
          this.loading = false
        }) // errors get caught in sendOutput
      } else {
        step()
      }
    }

    if (validate === undefined) {
      steppr()
    } else {
      validate().then((v) => {
        if (v) {
          steppr()
        }
      })
    }
  }

  public evaluateOutput() {
    this.loading = true
    return new Promise<void>((resolve, reject) => {
      const endpoint = this.currentStep.output
      const output = this.currentStep.output_format
      const context = this.currentStep.context
      let payload = null
      if (output) {
        payload = this.getPayload(output, context)
      }
      if (endpoint?.endsWith('/v1/driver/add_profile_image|POST') && !(payload?.attachment_token?.length > 0)) {
        resolve()
        return // ultra specific shitfix
      }

      if (endpoint && output) {
        this.sendOutput(resolve, reject, endpoint, this.getPayload(output, context))
      } else {
        resolve()
      }
    })
  }

  public sendOutput(success: () => void, failure: () => void, endpoint: string, payload: any) {
    const doStuffWithSuccess = (r: any) => {
      // BEHOLD MY SHAMES
      if (endpoint.includes('frontoffice/v2/flows')) {
        this.updateFlows(r)
      }
      if (endpoint?.endsWith('/v1/driver/add_profile_image|POST')) {
        const id = this.currentStep.fields?.find((f) => f.type === 'selfie')?.id
        if (id) {
          Utils.setProp(this.flowOutputState, [this.flowName, id, 'sent'], true)
        }
      }
      if (r.token) {
        this.flowOutputState[this.flowName].registration_response = r as LoginResponse
      }
      if (this.currentStep.output_success && !this.currentFlow.dialog) {
        this.$dialog.open(ConfirmDialog, {
          props: {
            imageState: null,
            code: '',
            title: '',
            subtitle: this.currentStep.output_success,
            confirmText: this.$t('common.continue'),
            singleAction: true,
          },
        })
      }
    }
    const doStuffAfterSuccess = () => {
      if (this.currentStep.output_success && this.currentFlow.dialog) {
        this.$dialog.open(ConfirmDialog, {
          props: {
            imageState: null,
            code: '',
            title: '',
            subtitle: this.currentStep.output_success,
            confirmText: this.$t('common.continue'),
            singleAction: true,
          },
        })
      }
    }
    const doStuffWithFailure = (e: AxiosError) => {
      if (e.response?.data?.result_code === 'exceptions.system.already-has-account-exception') {
        this.$dialog.open(AlreadyHasAccount, {
          props: {err: e.response.data},
        })
      }
      if (this.currentStep.output_failure) {
        this.$dialog.open(ErrorView, {
          props: {
            title: '',
            subtitle: this.currentStep.output_failure,
          },
        })
      }
      if (e.response?.data?.result_code === 'exceptions.auth.not-found-exception') {
        this.flowOutputState[this.flowName].client_token = null
      }
    }
    const stopLoading = () => {
      this.loading = false
    }
    this.loading = true

    this.send({endpoint, payload})
      .then(doStuffWithSuccess)
      .finally(stopLoading)
      .then(success)
      .then(doStuffAfterSuccess)
      .catch((e: AxiosError) => {
        doStuffWithFailure(e)
        return failure()
      })
  }

  protected created() {
    const flow = this.flowName
    if (flow) {
      this.initialLoading = this.flowInputs[flow]?.loading_message || ''
      this.getFlows().then(() => {
        this.steps = this.flowInputs[flow]?.steps || []
        const firstNotHidden = this.steps.findIndex((s) => !s.skip)
        if (firstNotHidden === -1) {
          this.$dialog.close()
        } else {
          this.step = firstNotHidden
        }
        this.initialLoading = null
        this.$emit('flow-loaded')
      })
    }
  }

  protected mounted() {
    EventBus.$on('flowStepBack', debounce(this.stepB, 1000, {isImmediate: true}))
    EventBus.$on('flowStepForward', debounce(this.stepF, 1000, {isImmediate: true}))
    EventBus.$on('flow-loading', (val: boolean) => {
      this.$nextTick(() => {
        this.$set(this, 'loading', val)
      })
    })
    EventBus.$on('flow-lock', (val: boolean) => {
      this.$set(this, 'locked', val)
    })
    // this.$smoothReflow?.({
    //   property: ['height', 'transform'],
    //   el: '.flow-step',
    //   hideOverflow: true
    // })
  }

  protected beforeDestroy() {
    EventBus.$off('flowStepForward')
    EventBus.$off('flowStepBack')
    EventBus.$off('flow-loading')
    EventBus.$off('flow-lock')
  }

  private stepB() {
    this.stepper(-1)
  }

  private stepF() {
    this.stepper(1, (this.$refs.obs as any).validate)
  }
}
