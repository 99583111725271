









import {Component, Prop, Vue} from 'vue-property-decorator'
import {VLayout, VFlex, VIcon} from 'vuetify/lib'
import ProgressCircular from '@/components/proxy/ProgressCircular.vue'

@Component({
  components: {
    ProgressCircular,
    VLayout,
    VFlex,
    VIcon,
  },
})
export default class CloseButton extends Vue {
  @Prop({type: Boolean}) protected dialog!: boolean
  @Prop({type: Boolean, default: false}) protected absolute!: boolean

  @Prop({}) protected color!: string
  @Prop({default: false}) protected left!: boolean
  @Prop({type: String, default: () => 'mdi-close'}) protected icon!: string
  @Prop({type: Function}) protected closeCallback!: () => void
  @Prop({type: Boolean, default: false}) protected onlyCallback!: boolean
  @Prop({type: Boolean, default: false}) protected loading!: boolean

  protected closeAction() {
    if (this.closeCallback) {
      this.closeCallback()
    }
    if (this.onlyCallback) {
      return
    }
    if (this.dialog) {
      this.$dialog.close()
    } else {
      this.$popup.close()
    }
  }
}
