
















import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {ReservationPaginatedResponse, ReservationResponse} from '@/lib/kepler/interfaces'
import {Getter, State} from 'vuex-class'
import {BookingState} from '@/store/modules/booking'
import ReservationHelper from '@/lib/reservation'

@Component({
  components: {
    BookingActions: Utils.loadComponent('BookingActions'),
    ReservationHighlights: Utils.loadComponent('entities/reservation/Highlights'),
    ReservationVehicleAndDates: Utils.loadComponent('entities/reservation/ReservationVehicleAndDates'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Container: Utils.loadComponent('proxy/Container'),
    CardTitle: Utils.loadComponent('proxy/Card/CardTitle'),
    VehicleDetail: Utils.loadComponent('entities/vehicle/VehicleDetail'),
    Divider: Utils.loadComponent('proxy/Divider'),
  },
  name: 'HistoryBookingDetail',
})
export default class HistoryBookingDetail extends Vue {
  @State('booking') public booking!: BookingState
  @State((state) => state.profile.driver.id) public driverId!: string
  @State((state) => state.booking.reservations) public past!: ReservationPaginatedResponse
  @Getter('activeReservations') public activeReservations!: ReservationResponse[]
  @Getter('futureReservations') public futureReservations!: ReservationResponse[]

  @Prop({type: String, default: 'past'}) public state!: 'future' | 'past' | 'active'
  @Prop({type: Object, required: false}) public res?: ReservationResponse
  @Prop({type: Number, required: false}) public resNum?: number

  protected get showLocation() {
    const r = this.reservation
    if (r) {
      const isFuture = !!this.booking.futureReservations.find((res) => res.id === r.id)
      const isCurrent = ReservationHelper.isCurrent(r)
      return (isFuture || isCurrent) && (r.type === 'STR' || r.type?.endsWith('RT'))
    }
  }

  protected get showRates() {
    return this.reservation?.type !== 'STR'
  }

  public get sameDriver() {
    return this.driverId === this.reservation?.driver.id
  }

  private get reservation() {
    if (this.res) {
      return this.res
    } else {
      switch (this.state) {
        case 'future':
          return this.futureReservations.find((r) => r.number === this.resNum)
        case 'active':
          return this.activeReservations.find((r) => r.number === this.resNum)
        default:
          return this.past.items.find((r) => r.number === this.resNum)
      }
    }
  }
}
