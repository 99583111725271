import axios, {AxiosError, AxiosPromise, AxiosRequestConfig} from 'axios'

import store from '@/store/index'

import {envInstance} from '@/lib/plugins/env'

import ErrorView from '@/views/Error.vue'
import {
  AccountStatus,
  ActivePacket,
  AppConfig,
  Attachment,
  Autorenew,
  AvailabilityRequest,
  AvailabilityResponse,
  AvailablePaymentMethod,
  BookingRequestPaginatedResponse,
  BookRequest,
  Card,
  ChangePasswordRequest,
  ChangePasswordResponse,
  ChangePaymentMethod,
  ChangePinRequest,
  ChangePinResponse,
  CheckItem,
  CheckpointRequest,
  CheckPointResponse,
  CheckupResponse,
  Client,
  ClientType,
  CorporateBookRequest,
  CouponResponse,
  DeletePaymentMethod,
  Deposit,
  Dialog,
  Document,
  Driver,
  DriverEditRequest,
  Estimate,
  EstimateCategories,
  EstimateCurrent,
  EstimateRequest,
  EstimateRequestAll,
  EVChargingStation,
  EVChargingStationRequest,
  ExtraActionResponse,
  GenericResponse,
  Globalnote,
  InterventionParkingLot,
  InterventionRequest,
  InviteRequest,
  Invoice,
  InvoicePay,
  LoginByPhoneCodeRequest,
  LoginByPhoneRequest,
  LoginProviderName,
  LoginProviderResponse,
  LoginRequest,
  LoginResponse,
  ModifyRequest,
  Notification,
  OfflineHookData,
  OpenVehicleRequest,
  Packet,
  Page,
  PaginatedRequest,
  ParkingLot,
  Poi,
  Pos,
  Position,
  Profile,
  ProfileImageRequest,
  ProfilePatchRequest,
  QuickRegistrationRequest,
  Rate,
  Report,
  ReportRequest,
  ReportResponse,
  ReportType,
  ReservationEditRequest,
  ReservationExtendRequest,
  ReservationPaginatedRequest,
  ReservationPaginatedResponse,
  ReservationResponse,
  ResetPasswordRequest,
  RestHWRequest,
  ReverseGeocoderResponse,
  SearchRequest,
  ServiceZone,
  SocialLoginResponse,
  Subscription,
  SubscriptionPlan,
  TerminateRequest,
  TerminateResponse,
  Timestamps,
  Toc,
  TocDetail,
  TocResponse,
  TocSignResponse,
  TopupResponse,
  TransitRouteRequest,
  TransitRouteResponse,
  TransitStop,
  TransitStopsRequest,
  UserMesh,
  VehicleCategory,
  VehicleSlot,
  VerificationMethodResponse,
  ZoneResponse,
} from '@/lib/kepler/interfaces'
import {DriverLicense, RegistrationRequest} from '@/lib/kepler/RegistrationRequest'
import ServiceMesh from '@/lib/serviceMesh'
import {EventBus} from '@/main'

const axiosInstance = axios.create({
  baseURL: envInstance.API_URL,
})

const tokenIsAvailable: () => boolean = () => {
  const token = store.state.userToken
  return !(typeof (token) === 'undefined' || token === null || token.trim() === '')
}

const headerLang: () => string = () => {
  const confLang = store.state.configuration.appConfig ? store.state.configuration.appConfig.default_language : null
  const userLang = store.state.profile.language ? store.state.profile.language : null
  return userLang ? userLang : confLang ? confLang : 'en'
}

interface RequestConfig extends AxiosRequestConfig {
  clientOnly?: boolean
  secured?: boolean
  noPostProcess?: boolean
}

// Add a request interceptor
axiosInstance.interceptors.request.use((config: RequestConfig) => {
  const controller = new AbortController()
  config.signal = controller.signal
  // Do something before request is sent
  if (config.clientOnly) {
    if (!store.getters.mainDriver) {
      controller.abort('Not the main driver')
    }
  }
  if (!config.headers) {
    config.headers = {}
  }
  if (config.secured === undefined || config.secured) {
    if (!tokenIsAvailable()) {
      controller.abort('No login token is defined')
    } else {
      config.headers.Authorization = 'Bearer ' + store.state.userToken
    }
  }
  config.headers.lang = headerLang()

  return config
}, (error) => {
  // Do something with request error
  return Promise.reject(error)
})

// Add a response interceptor
axiosInstance.interceptors.response.use((response) => {
  // Do something with response data
  // --> NO ERRORS
  if (!store.getters.isOnline && response.status === 200) {
    store.dispatch('setOnline', true)
  }
  return response
}, (error: AxiosError) => {

  if ((!navigator.onLine || error.message === 'Network Error')) {
    if (store.getters.isOnline) {
      store.dispatch('setOnline', false)
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }

  const req = error.request as XMLHttpRequest
  const conf = error.config as RequestConfig

  if (conf?.noPostProcess) {
    return Promise.reject(error)
  }

  // Do something with response error
  // const messages = error.messages.join(', ')
  // if (response.status == 403) {
  // --> ERROR!
  // console.log('FUCKING ERROR: ', error.response)
  //  alert("403! You will now be logged out");
  //  localStorage.removeItem('backoffice-token')
  // }

  const code = (e: AxiosError) => {
    if (e.response) {
      const status: number = e.response.status
      const statusText: string | undefined = e.response.statusText
      return `status ${status}` + e.response.statusText ? `: ${statusText}` : ''
    }
    return ''
  }

  const message = (e: AxiosError) => {
    if (e.response) {
      const messages: [string] = e.response.data.messages
      return messages?.join('\n') || ''
    }
    return ''
  }

  const openErrorDialog = () => {
    const errorCode = code(error)
    const errorTitle = error.response ? error.response.data.result : 'error'
    let errorSubtitle = message(error)
    const errorProps = {
      code: errorCode,
      title: errorTitle,
      subtitle: errorSubtitle,
    }

    store.dispatch('openDialog', new Dialog(ErrorView, errorProps))

    EventBus.$off('InversBtOpenFailed')
    EventBus.$off('InversBtCloseFailed')
  }

  if (req && req.responseURL) {
    const responseUrl = req.responseURL
    if (responseUrl.endsWith('v2/public/poi') && JSON.parse(req.responseText).result === 'exceptions.system.not-found-http-exception') {
      return Promise.reject
    }
  }

  if (axios.isCancel(error)) {
    return Promise.reject(error)
  }

  if (error.response && error.response.data.result_code === 'exceptions.system.already-has-account-exception') {
    throw error
  }

  if (error.response && error.response.data.result_code === 'exceptions.reservation.zone-exception') {
    return Promise.reject(error)
  }

  if (error.message === 'No login token is defined') {
    return Promise.reject(error)
  }

  if (message(error) === 'auth.invalid') {
    store.dispatch('logout')
    // this cuts off further promises, but...
    // location.reload()
    // TODO: figure this out, with multiple requests in queue it repeats the alert for each.
    // is there some way to stop further promises after the first that returns 403?
    // alert("Invalid token, you have been logged out.")
  }

  if (!error.response) {
    return Promise.reject(error)
  }

  openErrorDialog()

  return Promise.reject(error)
})

const noAuth = (extra: RequestConfig = {}) => {
  return Object.assign({secured: false}, extra)
}

const noPostProcess = (extra: RequestConfig = {}) => {
  return Object.assign({noPostProcess: true}, extra)
}

const clientOnly = (extra: RequestConfig = {}) => {
  return Object.assign({clientOnly: true}, extra)
}

export default {
  booking: {
    search: (req: Partial<SearchRequest>) => {
      if (tokenIsAvailable()) {
        return axiosInstance.get('v1/fleet/search', {params: req}) as AxiosPromise<VehicleSlot[]>
      } else {
        return axiosInstance.get('v1/public_search', noAuth({params: req})) as AxiosPromise<VehicleSlot[]>
      }
    },
    availabilty: (req: AvailabilityRequest) => {
      return axiosInstance.post('v1/fleet/vehicle_availability', req) as AxiosPromise<AvailabilityResponse[]>
    },
    estimate: (req: EstimateRequest) => {
      return axiosInstance.post('v1/reservation/estimate', req) as AxiosPromise<Estimate>
    },
    estimateAll: (req: EstimateRequestAll) => {
      return axiosInstance.post('v1/reservation/estimate_all', req) as AxiosPromise<EstimateCategories[]>
    },
    estimateCurrent: (resnum: number) => {
      return axiosInstance.get(`v3/frontoffice/reservations/${resnum}/current_cost`) as AxiosPromise<EstimateCurrent>
    },
    book: (req: BookRequest) => {
      return axiosInstance.post('v1/reservation/book', req) as AxiosPromise<ReservationResponse>
    },
    autoBook: (req: CorporateBookRequest) => {
      return axiosInstance.post('frontoffice/v2/reservations/auto_book', req) as AxiosPromise<ReservationResponse>
    },
    serviceMesh: () => {
      if (tokenIsAvailable()) {
        return axiosInstance.get('v3/frontoffice/utils/service_mesh') as AxiosPromise<ServiceMesh[]>
      } else {
        return axiosInstance.get('frontoffice/v2/config/service_mesh', noAuth()) as AxiosPromise<ServiceMesh[]>
      }
    },
    current: () => {
      return axiosInstance.get('v1/reservation/current') as AxiosPromise<ReservationResponse[]>
    },
    open: (req: OpenVehicleRequest) => {
      return axiosInstance.post(`/v1/reservation/${req.reservation_number}/open`, req, noPostProcess())
    },
    close: (req: RestHWRequest) => {
      return axiosInstance.post(`/v1/reservation/${req.reservation_number}/close`, req, noPostProcess())
    },
    terminate: (req: TerminateRequest) => {
      return axiosInstance.post(`frontoffice/v2/reservations/${req.reservation_number}/terminate`, req, noPostProcess()) as AxiosPromise<TerminateResponse>
    },
    getByNumber: (num: number) => {
      return axiosInstance.get(`v1/reservation/${num}`) as AxiosPromise<ReservationResponse>
    },
    zones: (id: string) => {
      if (tokenIsAvailable()) {
        return axiosInstance.get(`frontoffice/v2/vehicle_slots/${id}/zones`) as AxiosPromise<ZoneResponse>
      } else {
        return axiosInstance.get(`frontoffice/v2/public/vehicle_slot/${id}/zones`, noAuth()) as AxiosPromise<ZoneResponse>
      }
    },
    diaryReports(vehicleId: string) {
      return axiosInstance.get(`v1/fleet/${vehicleId}/diary_report`) as AxiosPromise<Report[]>
    },
    rating(reservation: number, payload: any) { // TODO: replace this any with proper typing
      return axiosInstance.post(`frontoffice/v2/reservations/${reservation}/rating`, payload) as AxiosPromise<ReservationResponse>
    },
    reportingTypes() {
      return axiosInstance.get(`v1/reportings/type`) as AxiosPromise<ReportType[]>
    },
    report(req: ReportRequest) {
      return axiosInstance.post(`v1/reportings/report/`, req) as AxiosPromise<ReportResponse[]>
    },
    categories() {
      if (tokenIsAvailable()) {
        return axiosInstance.get('v1/fleet/categories') as AxiosPromise<VehicleCategory[]>
      } else {
        return axiosInstance.get('v1/public_categories', noAuth()) as AxiosPromise<VehicleCategory[]>
      }
    },
    futureReservations() {
      return axiosInstance.get(`frontoffice/v2/reservations/future`) as AxiosPromise<ReservationResponse[]>
    },
    deleteReservation(req: ReservationResponse) {
      return axiosInstance.delete(`v1/reservation/${req.number}`) as AxiosPromise
    },
    editReservation(req: ReservationEditRequest) {
      return axiosInstance.patch(`v1/reservation/${req.reservation_number}/edit`, req) as AxiosPromise<ReservationResponse>
    },
    extendReservation(req: ReservationExtendRequest) {
      return axiosInstance.patch(`v1/reservation/${req.reservation_number}/extend`, req) as AxiosPromise<ReservationResponse>
    },
    getFuelPin(reservationNumber: number) {
      return axiosInstance.get(`/frontoffice/v2/reservations/${reservationNumber}/fuel_pin`) as AxiosPromise
    },
    getParkingLots(position: Pos, bookingMode: string = 'OWFF') {
      if (tokenIsAvailable()) {
        return axiosInstance.get(`/frontoffice/v2/reservations/park?latitude=${position.lat}&longitude=${position.lng}&booking_mode=${bookingMode}`) as AxiosPromise<ParkingLot[]>
      }
      return axiosInstance.get(`frontoffice/v2/public/park/?latitude=${position.lat}&longitude=${position.lng}&booking_mode=${bookingMode}`, noAuth()) as AxiosPromise<ParkingLot[]>
    },
    getPublicLots() {
      return axiosInstance.get('v1/public_lots', noAuth()) as AxiosPromise
    },
    getPrivateLots() {
      return axiosInstance.get('v1/fleet/lots') as AxiosPromise
    },
    sendLogs(channel: string, logs: string, driverId: string, platform: string, appVersion?: string) {
      return axiosInstance.post(`https://injest.playmoove.com/index.php?channel=${channel}`, {
        driverId,
        logs,
        platform,
        appVersion,
      }, noAuth())
    },
    getRequests(params: PaginatedRequest) {
      return axiosInstance.get(
        'v3/frontoffice/driver-reservation-requests',
        {params},
      ) as AxiosPromise<BookingRequestPaginatedResponse>
    },
    deleteRequest(id: string) {
      return axiosInstance.delete(`v3/frontoffice/driver-reservation-requests/${id}`) as AxiosPromise<GenericResponse>
    },
  },
  reservation: {
    get(params: ReservationPaginatedRequest) {
      return axiosInstance.get(`/v3/frontoffice/reservations`, {params}) as AxiosPromise<ReservationPaginatedResponse>
    },
    getExtra(reservationNumber: number) {
      return axiosInstance.get(`v3/frontoffice/reservations/${reservationNumber}`) as AxiosPromise<any[]>
    },
    sendCommand(reservationNumber: number, operation: string, payload?: Record<string, any>) {
      return axiosInstance.post(`v3/frontoffice/reservations/${reservationNumber}/${operation}`, payload) as AxiosPromise<ExtraActionResponse>
    },
    checkup(reservationNumber: number) {
      return axiosInstance.get(`v3/frontoffice/reservations/${reservationNumber}/checkup`, noPostProcess()) as AxiosPromise<CheckupResponse>
    },
    terminate_checkup(reservationNumber: number) {
      return axiosInstance.get(`v3/frontoffice/reservations/${reservationNumber}/terminate_checkup`) as AxiosPromise<CheckItem[]>
    },
    keys(reservationNumber: number) {
      return axiosInstance.get(`v3/frontoffice/reservations/${reservationNumber}/keys`, noPostProcess()) as AxiosPromise
    },
    otp: {
      request(reservationNumber: number) {
        return axiosInstance.post(`v3/frontoffice/reservations/${reservationNumber}/otp/request`)
      },
      validate(reservationNumber: number, otp: string) {
        return axiosInstance.post(`v3/frontoffice/reservations/${reservationNumber}/otp/validate`, {otp})
      },
    },
  },
  map: {
    getPoi: () => {
      return axiosInstance.get('frontoffice/v2/public/poi', noAuth()) as AxiosPromise<Poi[]>
    },
    vehicles: (req?: Position) => {
      return axiosInstance.get('v1/public_search', noAuth({params: req})) as AxiosPromise<VehicleSlot[]>
    },
    serviceZones() {
      return axiosInstance.get('v3/public/maps/zones', noAuth()) as AxiosPromise<ServiceZone[]>
    },
    getRoute: (req: { pos: Pos[], mode?: string }) => { // very vomit much barf 🤮
      const posArr = req.pos.map((p) => {
        return 'pos[]=' + p.lat + ',' + p.lng
      })
      return axiosInstance.get(`v3/frontoffice/utils/directions${req.pos.length || req.mode ? '?' : ''}${posArr.join('&')}${req.mode ? '&mode=' + req.mode : ''}`, noPostProcess()) as AxiosPromise<Pos[][]>
    },
    getEvChargingStations: (params: EVChargingStationRequest) => {
      return axiosInstance.get('v3/frontoffice/charge/stations', {params}) as AxiosPromise<EVChargingStation[]>
    },
  },
  transit: {
    stops: (params: TransitStopsRequest) => {
      return axiosInstance.get('v3/public/transit/stops?lat=27&lng=9', {params}) as AxiosPromise<TransitStop[]>
    },
    routes: (params: TransitRouteRequest) => {
      return axiosInstance.get('v3/public/transit/route', {params}) as AxiosPromise<TransitRouteResponse>
    },
  },
  subscription: {
    subscriptions: () => {
      return axiosInstance.get('v1/client/subscriptions', clientOnly()) as AxiosPromise<Subscription[]>
    },
    availablePlans: () => {
      return axiosInstance.get('v1/subscriptions/available_subscriptions') as AxiosPromise<SubscriptionPlan[]>
    },
    publicPlans: () => {
      return axiosInstance.get('/frontoffice/v2/public/subscriptions', noAuth()) as AxiosPromise<SubscriptionPlan[]>
    },
    rates: (planId: string) => {
      return axiosInstance.get(`/frontoffice/v2/plans/${planId}/rates`) as AxiosPromise<Rate[]>
    },
    subscribe: (planId: string) => {
      return axiosInstance.post('v1/subscription/subscribe', {plan_id: planId}) as AxiosPromise<Subscription>
    },
    addDriver: (subscription_id: string, driver_id: string) => {
      return axiosInstance.post('v1/subscription/add_driver', {subscription_id, driver_id}) as AxiosPromise
    },
    planTocs: (toc_id: string) => {
      return axiosInstance.get(`v1/tocs/${toc_id}`) as AxiosPromise<Toc | TocDetail>
    },
    autoRenew: (payload: Autorenew) => {
      return axiosInstance.patch(`v1/subscriptions/${payload.subscription_id}/autorenew`, {status: payload.status}) as AxiosPromise<Subscription>
    },
  },
  billing: {
    wallets: () => {
      return axiosInstance.get('/frontoffice/v2/billing/wallets')
    },
    topUpCredit: (amount: number) => {
      return axiosInstance.post('/frontoffice/v2/billing/topup', {amount}) as AxiosPromise<TopupResponse>
    },
    records: (id: string, account_type: string, month: string) => {
      return axiosInstance.get(`/frontoffice/v2/billing/${id}/records`, {params: {account_type, month}}) as AxiosPromise
    },
    payment_methods: () => {
      return axiosInstance.get('v1/payment_methods')
    },
    payment_methods_available: () => {
      return axiosInstance.get('/frontoffice/v2/payment_methods/available') as AxiosPromise<AvailablePaymentMethod[]>
    },
    payment_method_url: (provider: string) => {
      return axiosInstance.get('/frontoffice/v2/payment_methods/generate_nonce_url', {params: {provider}}) as AxiosPromise<string>
    },
    payment_method_add: (req: Card) => {
      return axiosInstance.post('v1/payment_methods/add_by_info', req)
    },
    payment_method_delete: (req: DeletePaymentMethod) => {
      return axiosInstance.delete('v1/payment_methods/delete', {data: req})
    },
    invoices_list: (month: string) => {
      return axiosInstance.get('/frontoffice/v2/billing/invoices', {params: {month}}) as AxiosPromise<Invoice[]>
    },
    invoice_pay: (invoiceId: string, req?: InvoicePay) => {
      return axiosInstance.post(`v1/invoices/${invoiceId}/pay`, req ? req : {}) as AxiosPromise<Invoice>
    },
    change_payment_method: (req: ChangePaymentMethod) => {
      return axiosInstance.post('/v1/wallet/add_payment_method', req) as AxiosPromise
    },
    deposits: (month: string) => {
      return axiosInstance.get('/frontoffice/v2/billing/deposits', {params: {month}}) as AxiosPromise<Deposit[]>
    },
    coupon: (uuid_short: string) => {
      return axiosInstance.post('/v3/frontoffice/coupons', {uuid_short}) as AxiosPromise<CouponResponse>
    },
    invoices: {
      pay: (invoicesToPay: string[], paymentMethod: string) => {
        return axiosInstance.post('/v3/frontoffice/billing/invoices/pay', {
          invoices_to_pay: invoicesToPay,
          payment_method: paymentMethod,
        }) as AxiosPromise
      },
    },
    packets: {
      get: () => {
        return axiosInstance.get('/v3/frontoffice/packets') as AxiosPromise<Packet[]>
      },
      active: () => {
        return axiosInstance.get('/v3/frontoffice/packets/active') as AxiosPromise<ActivePacket[]>
      },
      purchase: (packet_id: string) => {
        return axiosInstance.post('/v3/frontoffice/packets', {packet_id}) as AxiosPromise
      },
    },
  },
  reporting: {
    list: () => {
      return axiosInstance.get('v1/reportings/list')
    },
  },
  people: {
    isLogged: () => {
      return tokenIsAvailable()
    },
    login: (req: LoginRequest): AxiosPromise<LoginResponse> => {
      return axiosInstance.post('v1/login', req, noAuth()) as AxiosPromise<LoginResponse>
    },
    userMesh: (): AxiosPromise => {
      return axiosInstance.get('frontoffice/v2/plans/user_mesh') as AxiosPromise<UserMesh[]>
    },
    client: () => {
      return axiosInstance.get('v1/client') as AxiosPromise<Client>
    },
    clientEdit: (req: ModifyRequest) => {
      return axiosInstance.post('v1/client/edit', req)
    },
    status: (bookingMode: string) => {
      return axiosInstance.get('v1/drivers/account_status/' + bookingMode)
    },
    clientTypes: () => {
      return axiosInstance.get('v1/client/types', noAuth()) as AxiosPromise<ClientType>
    },
    licenseTypes: () => {
      return axiosInstance.get('v1/license/types', noAuth())
    },
    globalNotes: () => {
      return axiosInstance.get('frontoffice/v2/config/global_notes', noAuth()) as AxiosPromise<Globalnote[]>
    },
    register: (req: RegistrationRequest) => {
      return axiosInstance.post('v1/client/register', req, noAuth())
    },
    quickRegister: (req: QuickRegistrationRequest) => {
      return axiosInstance.post('frontoffice/v2/auth/register', req, noAuth()) as AxiosPromise
    },
    forgotPassword(req: ResetPasswordRequest) {
      return axiosInstance.post('v1/driver/password/forgot', req, noAuth())
    },
    drivers: () => {
      return axiosInstance.get('v1/drivers', clientOnly())
    },
    driver: () => {
      return axiosInstance.get('v1/driver') as AxiosPromise<Driver>
    },
    driverEdit(req: DriverEditRequest) {
      return axiosInstance.put('v1/driver/edit', req) as AxiosPromise<Driver>
    },
    inviteDriver(req: InviteRequest) {
      return axiosInstance.post('/v1/clients/drivers/invite', req) as AxiosPromise
    },
    inviteClient(req: InviteRequest) {
      return axiosInstance.post('/v3/frontoffice/drivers/invite_client', req) as AxiosPromise
    },
    getDocuments() {
      return axiosInstance.get('v1/driver_license') as AxiosPromise<Document[]>
    },
    requestPhoneVerification(req: LoginByPhoneCodeRequest) {
      return axiosInstance.post('frontoffice/v2/auth/request_phone_validation', req, noAuth())
    },
    phoneVerification(req: LoginByPhoneRequest) {
      return axiosInstance.post('frontoffice/v2/auth/validate_phone', req, noAuth())
    },
    requestLoginByPhone(req: LoginByPhoneCodeRequest) {
      return axiosInstance.post('frontoffice/v2/auth/request_login_by_phone', req, noAuth())
    },
    loginByPhone(req: LoginByPhoneRequest) {
      return axiosInstance.post('frontoffice/v2/auth/login_by_phone', req, noAuth())
    },
    addDocument(req: DriverLicense) {
      return axiosInstance.post('frontoffice/v2/documents', req) as AxiosPromise<Document>
    },
    suspendDriver(req: Driver) {
      return axiosInstance.patch(`v1/driver/${req.id}/suspend`, req) as AxiosPromise
    },
    reactivateDriver(req: Driver) {
      return axiosInstance.patch(`v1/driver/${req.id}/reactivate`, req) as AxiosPromise
    },
    addProfilePicture(req: ProfileImageRequest) {
      return axiosInstance.post('v1/driver/add_profile_image', req) as AxiosPromise<Driver>
    },
    accountStatus() {
      return axiosInstance.get(`v1/drivers/account_status`) as AxiosPromise<AccountStatus>
    },
    myDriversReservations(params: PaginatedRequest) {
      return axiosInstance.get(`frontoffice/v2/clients/reservations`, {params}) as AxiosPromise<ReservationPaginatedResponse>
    },
  },
  app: {
    config() {
      return axiosInstance.get(`v1/config`, noAuth()) as AxiosPromise<AppConfig>
    },
    timestamps() {
      return axiosInstance.get('frontoffice/v2/drivers/timestamps') as AxiosPromise<Timestamps>
    },
    status() {
      return axiosInstance.get('status') as AxiosPromise
    },
    pages(name: string) {
      return axiosInstance.get('v3/public/pages/' + name, noAuth()) as AxiosPromise<Page>
    },
  },
  timestamps: {
    now: () => Math.round(new Date().getTime() / 1000),
    verify: (timestamp: number | null, threshold?: number) => {
      if (threshold === undefined) {
        threshold = 300
      }
      return timestamp === null || (new Date().getTime() / 1000) - timestamp >= threshold
    },
  },
  tocs: {
    getTocs() {
      if (!tokenIsAvailable()) {
        return axiosInstance.get('frontoffice/v2/public/tocs', noAuth()) as AxiosPromise<TocResponse[]>
      } else {
        return axiosInstance.get(`frontoffice/v2/tocs`) as AxiosPromise<TocResponse[]>
      }
    },
    getSingleToc(id: string) {
      return axiosInstance.get(`frontoffice/v2/tocs/${id}`) as AxiosPromise<TocResponse>
    },
    signToc(id: string) {
      return axiosInstance.post(`frontoffice/v2/tocs/${id}/sign`) as AxiosPromise<TocSignResponse>
    },
    unsignToc(id: string) {
      return axiosInstance.post(`frontoffice/v2/tocs/${id}/unsign`) as AxiosPromise
    },
  },
  // firebase: {
  //   sendToken(token: string) {
  //     return axiosInstance.post('frontoffice/v2/drivers/push_token?XDEBUG_SESSION_START', {token}) as AxiosPromise
  //   },
  // },
  flows: {
    get() {
      if (store.state.userToken) {
        return axiosInstance.get('frontoffice/v2/flows') as AxiosPromise
      } else {
        return axiosInstance.get('frontoffice/v2/public/flows', noAuth()) as AxiosPromise
      }
    },
    getNotifications() {
      return axiosInstance.get('frontoffice/v2/flows/persistent') as AxiosPromise
    },
    send(endpointString: string, payload: unknown) {
      const endpointArr = endpointString.split('|')
      const actionName = endpointArr[1].trim().toLowerCase()
      const endpoint = endpointArr[0]

      let config: RequestConfig = {}
      if (endpointArr.includes('NOAUTH')) {
        config = noAuth(config)
      }

      if (actionName === 'get') {
        config.params = payload
        return axiosInstance.get(endpoint, config)
      }

      let actions: Map<string, (url: string, data: unknown, config: RequestConfig) => AxiosPromise> = new Map(Object.entries({
        'post': (url, data, config) => axiosInstance.post(url, data, config),
        'patch': (url, data, config) => axiosInstance.patch(url, data, config),
        'put': (url, data, config) => axiosInstance.put(url, data, config),
      }))

      if (actionName && actions.has(actionName)) {
        return actions.get(actionName)!(endpoint, payload, config)
      } else {
        return actions.get('post')!(endpoint, payload, config)
      }
    },
  },
  profile: {
    get() {
      return axiosInstance.get('v3/frontoffice/profile') as AxiosPromise<Profile>
    },
    patch(payload: ProfilePatchRequest) {
      return axiosInstance.patch('v3/frontoffice/profile', payload) as AxiosPromise<Profile>
    },
    setFlag(key: string, value: string) {
      return axiosInstance.post('frontoffice/v2/drivers/flag', {
        key,
        value,
      }) as AxiosPromise
    },
    checkpoint(payload: CheckpointRequest) {
      return axiosInstance.post('v3/frontoffice/profile/checkpoint', payload) as AxiosPromise<CheckPointResponse>
    },
    change_password(payload: ChangePasswordRequest) {
      return axiosInstance.post('v3/frontoffice/profile/change_password', payload) as AxiosPromise<ChangePasswordResponse>
    },
    change_pin(payload: ChangePinRequest) {
      return axiosInstance.post('v3/frontoffice/profile/change_pin', payload) as AxiosPromise<ChangePinResponse>
    },
    get_qr(payload: { [key: string]: string }) {
      return axiosInstance.get(`v3/public/qr/${Object.keys(payload)[0]}/${Object.values(payload)[0]}
?format=svg`)
    },
    get_client_token() {
      return axiosInstance.get('v3/frontoffice/clients/token') as AxiosPromise<{ client_token: string }>
    },
    get_invite_token() {
      return axiosInstance.get('v3/frontoffice/drivers/invitation_token') as AxiosPromise<{ driver_token: string }>
    },
    request_deletion(additional_info: string) {
      return axiosInstance.post('v3/frontoffice/profile/request_deletion', {additional_info})
    },
    recover_pin() {
      return axiosInstance.post('v3/frontoffice/profile/recover_pin', noPostProcess()) as AxiosPromise<void>
    },
    logout() {
      return axiosInstance.post('v3/frontoffice/profile/logout') as AxiosPromise<void>
    },
    get_verification(provider?: string) {
      return axiosInstance.get('v3/frontoffice/profile/get_verification', {params: {provider}}) as AxiosPromise<VerificationMethodResponse>
    },
    notifications: {
      get_all() {
        return axiosInstance.get('v3/frontoffice/profile/notifications') as AxiosPromise<Notification[]>
      },
      mark_all_as_read() {
        return axiosInstance.put('v3/frontoffice/profile/notifications') as AxiosPromise<GenericResponse>
      },
      delete_all() {
        return axiosInstance.delete('v3/frontoffice/profile/notifications') as AxiosPromise<GenericResponse>
      },
      mark_as_read(id: string) {
        return axiosInstance.put(`v3/frontoffice/profile/notifications/${id}`) as AxiosPromise<GenericResponse>
      },
      delete(id: string) {
        return axiosInstance.delete(`v3/frontoffice/profile/notifications/${id}`) as AxiosPromise<GenericResponse>
      }
    }
  },
  geocoding: {
    reverse(pos: Position) {
      return axiosInstance.get(`v3/frontoffice/utils/reverse_geocode`, {params: pos}) as AxiosPromise<ReverseGeocoderResponse>
    },
  },
  upload(formData: FormData) {
    return axiosInstance.post('v1/attachment/upload', formData, noAuth()) as AxiosPromise<Attachment>
  },
  uploadBlob(blob: Blob) {
    const f = new FormData
    f.append('file', blob)
    return this.upload(f)
  },
  checkToken(token: string) {
    return axiosInstance.post('v3/public/auth/check-token', {token}, noAuth())
  },
  hooks: {
    pushReservationData(reservation: number, payload: OfflineHookData) {
      return axiosInstance.post('hooks/offline', {...payload, reservation}, {
        baseURL: envInstance.API_URL.slice(0, -4),
      }) as AxiosPromise
    },
  },
  maintenance: {
    parkingLots(pos: { latitude: number, longitude: number }) {
      return axiosInstance.get('v3/frontoffice/intervention-requests/parking_lots', {params: pos}) as AxiosPromise<InterventionParkingLot[]>
    },
    zones() {
      return axiosInstance.get('v3/frontoffice/intervention-requests/zones') as AxiosPromise<string[]>
    },
    get(params: { 'latitude': number, 'longitude': number, 'parking_lot_id'?: string, zone_name?: string }) {
      return axiosInstance.get('v3/frontoffice/intervention-requests', {params}) as AxiosPromise<InterventionRequest[]>
    },
    take(plate: string) {
      return axiosInstance.post('v3/frontoffice/intervention-requests/take', {plate}) as AxiosPromise<ReservationResponse>
    },
  },
  login: {
    requestProvider(provider?: LoginProviderName) {
      if(provider) {
        return axiosInstance.post(`v3/public/login/request/${provider}`, null, noAuth()) as AxiosPromise<LoginProviderResponse>
      } else {
        return axiosInstance.post('v3/public/login/request', null, noAuth()) as AxiosPromise<LoginProviderResponse>
      }
    },
    check(token: string) {
      return axiosInstance.post('v3/public/login/check/'+token, null, noAuth()) as AxiosPromise<SocialLoginResponse>
    },
  }
}
