
































import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action, Getter} from 'vuex-class'
import VehicleView from '@/views/Vehicle/Vehicle.vue'
import {FieldConfigs, VehicleSlot} from '@/lib/kepler/interfaces'
import DateHelper from '@/lib/DateHelper'
import Utils from '@/utils'
import ServiceMesh from '@/lib/serviceMesh'

interface Price {
  cost: string
  cycle: string
}

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Img: Utils.loadComponent('proxy/Image'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Plate: Utils.loadComponent('Plate'),
    Divider: Utils.loadComponent('proxy/Divider'),
    FuelLevel: Utils.loadComponent('FuelLevel'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    VehicleTypeChip: Utils.loadComponent('entities/vehicle/VehicleTypeChip'),
  },
})
export default class VehicleCardPopup extends Vue {
  @Action('setVehicleSlot') public setVehicleSlot!: (payload: VehicleSlot) => void
  @Getter('fieldConfigs') public fieldConfigs!: FieldConfigs

  @Prop({type: Object}) public vehicleSlot!: VehicleSlot

  public visible: boolean = false
  public typeKey: string = ''

  public price: null | Price = null

  protected get vehicleTypeColor() {
    const key = `${this.vehicleSlot.reservation_type}${this.vehicleSlot.vehicle.category.type}`.toUpperCase()
    return {color: ServiceMesh.colors[key]}
  }

  protected get ffQrAutoUnlock() {
    const types = String(this.fieldConfigs.ff_qr_auto_unlock).toUpperCase().split('|')
    const type = this.vehicleSlot.vehicle.category.type.toUpperCase()
    const resType = this.vehicleSlot.reservation_type
    return types.includes(type) && resType === 'FF'
  }
  protected selected() {
    this.$popup.open(VehicleView, {
      props: {vehicleSlot: this.vehicleSlot},
      title: this.$t('vehicle.vehicle_detail'),
    })
  }

  protected visibilityChanged(isVisible: boolean) {
    this.visible = isVisible
  }

  protected startQRFlow() {
    const mode = this.vehicleSlot.reservation_type.toLowerCase()
    const type = this.vehicleSlot.vehicle.category.type.toLowerCase()
    const autoOpen = this.vehicleSlot.vehicle.plate
    this.$router.push({path: `/activities/booking/${type}/${mode}`, params: {mode, type}, query: {autoOpen}})
  }

  protected created() {
    this.typeKey = `${this.vehicleSlot.reservation_type}${this.vehicleSlot.vehicle.category.type}`.toUpperCase()
    if (this.vehicleSlot.base_rate?.info?.time_rate) {
      const [c, x] = this.vehicleSlot.base_rate.info?.time_rate.split('|')
      this.price = {
        cycle: DateHelper.parseMinutes(this, Number(x)),
        cost: this.$currency(c),
      }
    }
  }
}
