











































import {Component, Prop, Watch} from 'vue-property-decorator'

import Utils from '@/utils'
import Validations from '@/lib/Validations'

import AlreadyHasAccount from '@/views/AlreadyHasAccount.vue'
import {VBtn, VLayout, VProgressCircular, VProgressLinear} from 'vuetify/lib'
import {ValidationObserver} from 'vee-validate'
import moment from 'moment'
import {FlowInputStep, LoginResponse} from '@/lib/kepler/interfaces'
import {EventBus} from '@/main'
import {debounce} from '@/lib/Debounce'
import {AxiosError} from 'axios'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import ErrorView from '@/views/Error.vue'
import {mixins} from 'vue-class-component'
import {FlowMixin} from '@/components/flow/FlowMixin'

@Component({
  components: {
    VProgressLinear,
    VBtn,
    VProgressCircular,
    ValidationObserver,
    VLayout,
    Sheet: Utils.loadComponent('proxy/Sheet'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    FlowStep: Utils.loadComponent('flow/FlowStep'),
  },
})
export default class Flow extends mixins<FlowMixin>(FlowMixin) {
  @Prop({
    type: String,
    default: () => '',
  }) public readonly flowName!: string

  @Prop({
    type: Function,
  }) public readonly exitForward?: () => void
  @Prop({
    type: Function,
  }) public readonly exitBack?: () => void
  @Prop({
    type: Boolean,
    default: true,
  }) public readonly absolute!: boolean

  public rules: any = Validations.rules

  public startMoment: moment.Moment = moment()

  public step = 0
  public loading: boolean = false
  public locked: boolean = false
  public initialLoading: string | null = null

  public get currentStep() {
    return this.steps[this.step]
  }

  public get activeSteps() {
    return this.steps.filter((step) => {
      return !step.skip
    })
  }

  public get isLastStep() {
    return this.activeSteps.length ? this.activeSteps[this.activeSteps.length - 1].id === this.currentStep.id : null
  }

  public get steps(): FlowInputStep[] {
    return this.flowInputs[this.flowName]?.steps
  }

  public progressColor() {
    const lastStep = this.step < this.activeSteps.length
    return !lastStep || this.loading ? 'grey' : 'accent'
  }

  @Watch('step')
  public onStepChange(step: number) {
    this.$route.params.step = step.toString() // possibly useless?
  }

  public stepper(val: number, validate?: () => Promise<boolean>) {
    const startId = this.currentStep.id
    const steppr = () => {
      const step = () => {
        let pointer = this.steps.findIndex((s) => s.id === startId)
        let i = val
        while (i !== 0) {
          const newPointer = pointer + Math.sign(i) // decrease/increase pointer by 1
          if (newPointer < 0) {
            this.exitBack?.()
            return
          } else if (newPointer === this.steps.length) {
            this.exitForward ? this.exitForward() : (() => {
              this.purgeFlow(this.flowName)
              this.init()
              this.loading = false
            })()
            return
          }
          if (!this.steps[newPointer].skip) {
            i = i - Math.sign(i)
            pointer = newPointer
          } else {
            pointer = newPointer
          }
        }
        this.step = Math.min(Math.max(pointer, 0), this.steps.length) // clamp value between 0 and steps length
        this.loading = false
      }

      if (val > 0) {
        this.evaluateOutput().then(step).catch(() => {
          this.loading = false
        }) // to avoid getting stuck if request fails
      } else {
        step()
      }
    }

    if (validate === undefined) {
      steppr()
    } else {
      validate().then((v) => {
        if (v) {
          steppr()
        }
      })
    }
  }

  public evaluateOutput() {
    this.loading = true
    return new Promise<void>((resolve, reject) => {
      const endpoint = this.currentStep.output
      const output = this.currentStep.output_format
      const context = this.currentStep.context
      let payload = null
      if (output) {
        payload = this.getPayload(output, context)
      }
      if (endpoint?.endsWith('/v1/driver/add_profile_image|POST') && !(payload?.attachment_token?.length > 0)) {
        resolve()
        return // ultra specific shitfix
      }

      const endEvaluation = () => {
        if (this.currentStep.sleep) {
          setTimeout(resolve, this.currentStep.sleep * 1000)
        } else {
          resolve()
        }
      }

      if (endpoint && output) {
        this.sendOutput(endEvaluation, reject, endpoint, this.getPayload(output, context))
      } else {
        endEvaluation()
      }
    })
  }

  public sendOutput(success: () => void, failure: () => void, endpoint: string, payload: any) {
    const doStuffWithSuccess = (r: any) => {
      // BEHOLD MY SHAMES
      if (endpoint.includes('frontoffice/v2/flows')) {
        this.updateFlows(r)
      }
      if (endpoint?.endsWith('/v1/driver/add_profile_image|POST')) {
        const id = this.currentStep.fields?.find((f) => f.type === 'selfie')?.id
        if (id) {
          Utils.setProp(this.flowOutputState, [this.flowName, id, 'sent'], true)
        }
      }
      if (r.token) {
        this.flowOutputState[this.flowName].registration_response = r as LoginResponse
      }
      if (this.currentStep.output_success) {
        this.$dialog.open(ConfirmDialog, {
          props: {
            imageState: null,
            code: '',
            title: '',
            subtitle: this.currentStep.output_success,
            confirmText: this.$t('common.continue'),
            singleAction: true,
          },
        })
      }
    }
    const doStuffWithFailure = (e: AxiosError) => {
      if (e.response?.data?.result_code === 'exceptions.system.already-has-account-exception') {
        this.$dialog.open(AlreadyHasAccount, {
          props: {
            err: e.response.data,
          }, hideTopbar: false,
        })
      }
      if (this.currentStep.output_failure) {
        this.$dialog.open(ErrorView, {
          props: {
            title: '',
            subtitle: this.currentStep.output_failure,
          },
        })
      }
      if (e.response?.data?.result_code === 'exceptions.auth.not-found-exception') {
        this.flowOutputState[this.flowName].client_token = null
      }
    }

    this.send({endpoint, payload})
      .then(doStuffWithSuccess)
      .then(success)
      .catch((e: AxiosError) => {
        doStuffWithFailure(e)
        return failure()
      })
  }

  public init() {
    const flow = this.flowName
    this.step = 0
    if (flow && flow !== 'devFlow') {
      this.initialLoading = this.flowInputs[flow]?.loading_message || ''
      this.getFlows().then(() => {
        const steps = this.flowInputs[flow]?.steps || []
        const firstNotHidden = steps.findIndex((s) => !s.skip)
        if (firstNotHidden === -1) {
          // TODO: should find a way to know if flow is in popup or in a route and take the appropriate action
          this.$popup.close()
          this.$router.push({path: '/'})
        } else {
          this.step = firstNotHidden
        }
        this.initialLoading = null
        this.$emit('flow-loaded')
      })
    }
  }

  public created() {
    this.init()
  }

  protected mounted() {
    EventBus.$on('flowStepBack', debounce(this.stepB, 1000, {isImmediate: true}))
    EventBus.$on('flowStepForward', debounce(this.stepF, 1000, {isImmediate: true}))
    EventBus.$on('flow-loading', (val: boolean) => {
      this.$nextTick(() => {
        this.$set(this, 'loading', val)
      })
    })
    EventBus.$on('flow-lock', (val: boolean) => {
      this.$set(this, 'locked', val)
    })
  }

  protected beforeDestroy() {
    EventBus.$off('flowStepForward')
    EventBus.$off('flowStepBack')
    EventBus.$off('flow-loading')
    EventBus.$off('flow-lock')
  }

  private stepB() {
    this.stepper(-1)
  }

  private stepF() {
    this.stepper(1, (this.$refs.obs as any).validate)
  }

}
