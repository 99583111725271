import {BookingMode, Theme, Vehicle, VehicleSlot, VehicleType} from '@/lib/kepler/interfaces'
import {branding} from '@/lib/plugins/theme'

export default class ServiceMesh {
  public booking_mode!: string
  public vehicle_type!: string
  public vehicle_type_id!: string

  public static get colors() {
    const obj: Record<string, string> = {}
    this.getBmvt().forEach((key) => {
      const val = branding.theme[key as keyof Theme] as string | undefined
      obj[key] = val || '#AAAAAA'
    })
    return obj
  }

  public static bookingModes = Object.values(BookingMode)

  public static vehicleTypes = Object.values(VehicleType)

  public get vehicleType() {
    const obj: { [key: string]: string } = {}
    for (const vt of Object.values(VehicleType)) {
      for (const bm of Object.values(BookingMode)) {
        obj[bm + vt] = `vehicle.${vt.toLowerCase()}.${bm.toLowerCase()}`
      }
    }
    return obj
  }

  public static getExpandedColors() {
    const obj: Record<keyof typeof BookingMode, Record<keyof typeof VehicleType, string>> = {} as any
    this.getBmvt(true).forEach((key) => {
      const bmvt = key.split('.') as [keyof typeof BookingMode, keyof typeof VehicleType]
      const val = branding.theme[bmvt.join('') as keyof Theme] as string | undefined
      if (!obj[bmvt[0]]) {
        obj[bmvt[0]] = {} as any
      }
      obj[bmvt[0]][bmvt[1]] = val || '#AAAAAA'
    })
    return obj
  }

  public static getBmvt(expanded = false) {
    const arr: string[] = []
    this.bookingModes.forEach(bm => {
      this.vehicleTypes.forEach((vt) => {
        if (expanded) {
          arr.push(((`${bm}.${vt}`).toUpperCase()))
        } else {
          arr.push((bm + vt).toUpperCase())
        }
      })
    })
    return arr
  }

  public static splitBMVT(input: string): [BookingMode, VehicleType] | null {
    for (const mode in BookingMode) {
      if (input.startsWith(mode)) {
        const vehicle = input.slice(mode.length) as VehicleType;
        if (Object.values(VehicleType).includes(vehicle)) {
          return [mode as BookingMode, vehicle];
        }
      }
    }
    return null;
  }

  public static bmvt(vehicle_slot: VehicleSlot) {
    return `${vehicle_slot.reservation_type}${vehicle_slot.vehicle.category.type}`.toUpperCase()
  }

  public static bmvtColor(vehicle_slot: VehicleSlot) {
    return ServiceMesh.colors[ServiceMesh.bmvt(vehicle_slot)]
  }

  public static getImageForFuelLevel(vehicle: Vehicle) {

    switch (true) {
      case vehicle.fuel_level >= 50:
        return 'img/icons/battery_status-50.png'
      case vehicle.fuel_level <= 50:
        return 'img/icons/battery_status-10.png'
      default:
        return 'img/icons/battery_status-100.png'
    }
  }

  public static getPolyLineColor(zone: string) {
    switch (zone) {
      case 'allowed':
        return 'green'
      case 'forbidden':
        return 'red'
      case 'undesiderable':
        return 'yellow'
      default:
        return 'grey'
    }
  }

  public static getConfirmationImage(vehicleType: VehicleType) {
    return `img/booking/${vehicleType}.svg`
  }

  public static getVehicleType(vehicle: Vehicle) {
    return vehicle.category.type
  }

  public static getVehicleBookingMode(vehicleSlot: VehicleSlot) {
    return vehicleSlot.reservation_type
  }

  public static async getImagePin(v: VehicleType) {
    if (v.toLowerCase() !== 'parking' && ServiceMesh.vehicleTypes.indexOf(v.toUpperCase() as VehicleType) === -1) {
      throw new Error(v + ' is not present in the ServiceMesh')
    }
    return await import(/* webpackChunkName: "vehicle-image-[vt]" */ (`%/img/icons/${v.toLowerCase()}_pin_white.svg?inline`))
  }

  public getImage(bookingMode: BookingMode, vehicleType: VehicleType, level: string = 'OK'): string {
    if (ServiceMesh.bookingModes.indexOf(bookingMode) === -1) {
      console.error(bookingMode + ' is not present in the ServiceMesh')
    }
    if (ServiceMesh.vehicleTypes.indexOf(vehicleType.toUpperCase() as VehicleType) === -1) {
      console.error(vehicleType + ' is not present in the ServiceMesh')
    }
    return 'img/map/markers/' + vehicleType.toUpperCase() + '-' + bookingMode.toUpperCase() + '-' + level.toUpperCase() + '.png'
  }

  public getImageForVehicle(v: VehicleSlot, level: string = 'OK') {
    return this.getImage(v.reservation_type, v.vehicle.category.type, level)
  }

  public getImagePinWhite(v: VehicleSlot) {
    const vehicleType = v.vehicle.category.type
    if (ServiceMesh.vehicleTypes.indexOf(vehicleType.toUpperCase() as VehicleType) === -1) {
      console.error(vehicleType + ' is not present in the ServiceMesh')
    }
    return 'img/icons/' + vehicleType.toLowerCase() + '_pin_white.svg'
  }

  public getImageForParking(v: VehicleSlot, level: string = 'PARK') {
    return this.getImage(v.reservation_type, v.vehicle.category.type, level)
  }

  public getClusterImage(key: string) {
    // return 'img/map/clusters/' + ServiceMesh.getColorForKey(key) + '/'
    key = key.replace('-', '')
    return 'img/map/clusters/' + ServiceMesh.colors[key].replace('#', '').toUpperCase() + '/'
  }
}
