import {ActionTree, GetterTree, MutationTree} from 'vuex'
import {RootState} from '@/store'
import {BookingMode, VehicleType} from '@/lib/kepler/interfaces'
import {Vue} from 'vue-property-decorator'

export interface FilterButton {
  id: string
  name: string,
  icon?: string,
  color?: string,
  vehicle_types?: Array<keyof typeof VehicleType> | null,
  vehicle_categories?: string[] | null,
  booking_mode?: Array<keyof typeof BookingMode> | null
  zones?: string[] | null,
  poi?: string[] | null,
  parkings?: string[] | null,
  enabled: boolean,
  small?: boolean
}

export interface FilterGroup {
  id: string,
  title?: string,
  hidden?: boolean,
  items: FilterButton[]
}

interface FiltersMeta extends Record<string, any> {
  time_picker?: {
    name?: string
    description?: string
    range?: string,
  } | boolean,
  range_selector?: boolean,
}

export interface FilterStructure {
  meta: FiltersMeta
  groups: FilterGroup[]
}

export class CustomFilterState {
  public filtersCustom: FilterStructure | null = null
  public filtersDefault: FilterStructure | null = null
  public enabledFilters: string[] = []
}

const mutations: MutationTree<CustomFilterState> = {
  SET_CUSTOM_FILTERS: (state, payload: FilterStructure | null) => {
    state.filtersCustom = payload
  },
  SET_DEFAULT_FILTERS: (state, payload: FilterStructure | null) => {
    state.filtersDefault = payload
  },
  SET_FILTERS_ENABLED: (state, payload: string[]) => {
    state.enabledFilters = payload
  },
  MIGRATE_FILTERS: (state) => {
    Vue.delete(state, 'poi')
    Vue.delete(state, 'park')
    Vue.delete(state, 'zones')
    Vue.delete(state, 'vehicles')
    Vue.delete(state, 'parameters')
    Vue.set(state, 'filtersCustom', null)
    Vue.set(state, 'filtersDefault', null)
    Vue.set(state, 'enabledFilters', [])
  },
  ENABLE_FILTERS: (state, payload: FilterStructure | null) => {
    const enabledArr: string[] = []
    payload?.groups.forEach((g) => g.items.forEach((i) => {
      if (i.enabled) {
        enabledArr.push(i.id)
      }
    }))
    state.enabledFilters = enabledArr
  },
}

const actions: ActionTree<CustomFilterState, RootState> = {
  init({state, commit, dispatch}) {
    const oldKeys = ['poi', 'park', 'zones', 'vehicles', 'parameters']
    if (Object.keys(state).some((k) => oldKeys.includes(k))) {
      commit('MIGRATE_FILTERS') // in case of update from previous versions of this module, map.ts
      dispatch('sleep')
    } else {
      commit('SET_CUSTOM_FILTERS', null)
    }
  },
  setCustomFilters({commit, dispatch}, filters: FilterStructure | null) {
    commit('SET_CUSTOM_FILTERS', filters)
    commit('ENABLE_FILTERS', filters)
    dispatch('resetMapItemsRequest')
  },
  updateDefaultFilters({state, commit}, filters: FilterStructure) {
    commit('SET_DEFAULT_FILTERS', filters)
    if (!state.filtersCustom && state.enabledFilters.length === 0) {
      commit('ENABLE_FILTERS', filters)
    }
  },
  toggleFilterButton({state, commit, dispatch}, id: string) {
    const filters = new Set(state.enabledFilters)
    if (filters.has(id)) {
      filters.delete(id)
    } else {
      filters.add(id)
    }
    commit('SET_FILTERS_ENABLED', Array.from(filters))
    dispatch('sleep')
  },
}

const getters: GetterTree<CustomFilterState, RootState> = {
  getEnabledFilterButtons: (s) => {
    const results: FilterButton[] = [];
    (s.enabledFilters || []).forEach((id: string) => {
      const filters = s.filtersCustom || s.filtersDefault
      filters?.groups.forEach((g) => {
        const item = g.items.find((i) => i.id === id)
        if (item) {
          results.push(item)
        }
      })
    })
    return results.map((r) => {
      const {
        color,
        icon,
        name,
        small,
        enabled,
        ...cleaned
      } = r
      return cleaned
    })
  },
  getFilters: (s) => s.filtersCustom || s.filtersDefault,
}

export default {
  state: new CustomFilterState(),
  mutations,
  actions,
  getters,
}
