























import {Component, Watch} from 'vue-property-decorator'
import Utils from '../utils'
import {Action} from 'vuex-class'
import {
  BookTimeSelectRequest,
  BookingMode,
  ReservationResponse,
  VehicleCategory,
} from '@/lib/kepler/interfaces'
import sdk from '../lib/kepler/sdk'
import AutoComplete from '@/components/proxy/Inputs/AutoComplete.vue'
import SelectTag from '@/components/proxy/Inputs/SelectTag.vue'
import TextField from '@/components/proxy/Inputs/TextField.vue'
import moment from 'moment'
import RTVehicleSearch from '@/views/VehicleSearch.vue'
import Locate from '@/lib/location'

@Component({
  components: {
    TextField,
    SelectTag,
    AutoComplete,
    IsLogged: Utils.loadComponent('IsLogged'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'CorporateVehicleSearch',
})

export default class CorporateVehicleSearch extends RTVehicleSearch {
  @Action('current') public current!: () => Promise<void | ReservationResponse[]>

  public categories: any[] = []
  public bookingMode: keyof typeof BookingMode = 'CRT'

  public lotId = ''
  public catId = ''
  public memo = ''

  protected lots: any[] = []

  protected created() {
    sdk.booking.getPrivateLots().then((r) => {
      this.lots = r.data
    })
  }

  protected categoryString(category: VehicleCategory) {
    let type = category.type.toLowerCase()
    const name = category.name.toLowerCase()
    type = name.includes(type) ? '' : type + ' - '
    return type + name
  }

  protected toggleCategory(category: any) {
    if (category.id === this.searchRequest.vehicle_category_id) {
      this.searchRequest.vehicle_category_id = undefined
      return
    }
    this.searchRequest.vehicle_category_id = category.id
  }

  protected selectedFeatures() {
    return this.features.filter((feature) => {
      return feature.selected === true
    })
  }

  @Watch('innerValue', {deep: true})
  protected isValid(val: BookTimeSelectRequest) {
    const start = moment(val.start, 'YYYY-MM-DD HH:mm:ss')
    const end = moment(val.end, 'YYYY-MM-DD HH:mm:ss')
    if (start.isValid() && end.isValid() && start.isBefore(end)) {
      this.bookRequest.start = val.start
      this.bookRequest.end = val.end
    } else {
      this.bookRequest.start = ''
      this.bookRequest.end = ''
    }
  }

  protected setCategories() {
    const arr = this.categoriesState
    if (arr) {
      arr.forEach((category) => {
        this.$set(category, 'selected', true)
      })
      if (this.$route.params.type) {
        arr.filter((cat) => {
          return cat.type === this.$route.params.type
        })
      }
      this.categories = arr
    }
  }

  protected mounted() {
    this.setPos()
    this.setCategories()
    this.locate()
  }

  protected isCurrent(r: ReservationResponse) {
    if (r.start_timestamp && r.end_timestamp) {
      const start = moment(r.start_timestamp, 'X')
      const end = moment(r.end_timestamp, 'X')
      return moment().isBetween(start, end)
    }
  }

  protected isFuture(r: ReservationResponse) {
    if (r.start_timestamp) {
      const start = moment(r.start_timestamp, 'X')
      return moment().isBefore(start)
    }
  }

  protected submit() {
    this.loading = true
    Locate.please_locate((p) => {
      sdk.booking.autoBook({
        start: this.innerValue.start,
        end: this.innerValue.end,
        memo: this.memo,
        parking_lot_id: this.lotId,
        vehicle_category_id: this.catId,
        position: p ? {
          latitude: p.lat,
          longitude: p.lng,
        } : undefined,
      })
        .then((r) => {
        const reservation = r.data
        if (this.isCurrent(reservation)) {
            this.current().then(() => {
              this.$router.push({name: 'home'}).then(() => {
                this.$router.push({name: 'reservation', params: {id: reservation.id}})
              })
            })
          } else if (this.isFuture(r.data)) {
            this.$router.push({name: 'history', hash: '#future', params: {reloadHistory: 'future'}})
          }
        })
        .catch((e) => {
          this.$log(e, 2)
          this.loading = false
        })
    })
  }
}
