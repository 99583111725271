import {GetterTree} from 'vuex'
import {RootState} from '@/store'

export interface MenuButton {
  name?: string,
  icon?: string,
  image?: string,
  url?: string,
  path?: string,
  routeName?: string,
  flow?: string,
  color?: string,
  class?: string
  loggedIn?: string | boolean
  loggedOut?: string | boolean
  exclude?: string
}

interface MenuObj extends Partial<Record<string, string>> {
  class?: string
  src?: string
  icon?: string
  label?: string
}

export interface MenuState {
  name: string
  obj: MenuObj
  buttons: MenuButton[]
}

const getters: GetterTree<{}, RootState> = {
  menuHasProfileButton: (s, g) => {
    return !!g.menu.buttons.find((b: MenuButton) => b.path === 'profile' || b.routeName === 'profile')
  },
  menu: (s, g, rs, {isLogged, fieldConfigs}): MenuState => {
    const logged = isLogged
    const homeMenuStyle: string = fieldConfigs?.home_menu_style
    const homeMenuType = homeMenuStyle?.match(/^([^|]*)/)?.[0]
    let obj: MenuObj = {}
    switch (homeMenuType) {
      case 'logo':
        // example config string: logo|src=img/logo.png|class=accent--text
        obj = {src: 'img/icons/android-chrome-192x192.png', class: 'accent--text'}
        homeMenuStyle.split('|').forEach((str) => {
          const prop = str.split('=')
          if (prop.length > 1) {
            obj[prop[0]] = prop[1]
          }
        })
        return {name: 'logo', buttons: [], obj}
      case 'labeled':
        // example config string: labeled|icon=mdi-arrow-right-bold|label=go
        obj = {label: 'menu.bottom_bar.activities', icon: 'mdi-shape', class: ''}
        homeMenuStyle.split('|').forEach((str) => {
          const prop = str.split('=')
          if (prop.length > 1) {
            obj[prop[0]] = prop[1]
          }
        })
        return {name: 'labeled', buttons: [], obj}
      case 'buttons':
        // example config string:
        // buttons|icon=mdi-car-key&name=Share&path=bookingRequest
        // |icon=mdi-view-list&name=Booking&path=history
        // |icon=mdi-map&name=Map&path=home
        // |icon=mdi-account&name=Profile&path=profile&color=accent&class=accent--text
        // encode each button as a query string, like https://www.coderstool.com/querystring-encode
        const buttons: MenuButton[] = []
        homeMenuStyle.split('|').slice(1).forEach((b) => {
          const params = new URLSearchParams(b)
          const entries = params.entries()
          const button: MenuButton = Object.fromEntries(entries)
          const loggedIn = 'loggedIn' in button
          const loggedOut = 'loggedOut' in button
          if (loggedIn && logged || loggedOut && !logged || (!loggedIn && !loggedOut)) {
            buttons.push(button)
          }
        })
        return {name: 'buttons', buttons, obj: {}}
      default:
        return {name: 'hidden', buttons: [], obj: {}}
    }
  },
}

export default {
  state: {},
  getters,
}
