















































import {Vue, Component, Prop} from 'vue-property-decorator'
import {VListTileTitle} from 'vuetify/lib'
import {ReservationResponse, ReservationStatus} from '@/lib/kepler/interfaces'
import DateHelper from '@/lib/DateHelper'
import Utils from '@/utils'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import ServiceMesh from '@/lib/serviceMesh'
import ReservationHelper from '@/lib/reservation'
import moment from 'moment'

@Component({
  components: {
    Chip: Utils.loadComponent('proxy/Chip'),
    CardList: Utils.loadComponent('CardList'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CardButton: Utils.loadComponent('CardButton'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Img: Utils.loadComponent('proxy/Image'),
    VListTileTitle,
  },
  name: 'CardReservation',
})
export default class CardReservation extends Vue {
  @Prop() protected reservation!: ReservationResponse
  @Prop() protected future!: boolean
  @Prop() protected showDriver!: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) protected animated!: boolean

  protected getTime(r: ReservationResponse) {
    return DateHelper.formatTimeOffset('dhm', r.end_timestamp - r.start_timestamp)
  }

  protected get time() {
    const r = this.reservation
    return DateHelper.formatTimeOffset('dhm', r.end_timestamp - r.start_timestamp)
  }

  protected getDate(isStart: boolean) {
    const start = this.reservation.start
    const end = this.reservation.end

    if (start && end) {
      return moment(isStart ? start : end).format(this.$datetimeformat())
    }
  }

  protected getCost(reservation: ReservationResponse) {
    const n = Number(reservation.cost)
    return !Number.isNaN(n) && reservation.status === 'CHARGED' ? `${this.$currency(reservation.cost as any)}` : ''
  }

  protected get primaryColor() {
    return VuetifyColorHelper.color('primary')
  }

  protected get vehicleTypeColor() {
    const vs = this.reservation.vehicle_slot
    const key = `${vs.reservation_type}${vs.vehicle.category.type}`.toUpperCase()
    return ServiceMesh.colors[key]
  }

  protected get isLate() {
    return ReservationHelper.isLate(this.reservation)
  }

  protected get standardColor() {
    return this.isLate ? VuetifyColorHelper.color('error') : VuetifyColorHelper.color('success')
  }

  protected get vehicle() {
    return this.reservation.vehicle_slot.vehicle
  }

  public get statusColor() {
    switch (this.reservation.status) {
      case ReservationStatus.PLANNED:
      case ReservationStatus.SENT:
        return 'warning'
      case ReservationStatus.CONFIRMED:
      case ReservationStatus.STARTED:
      case ReservationStatus.COMPLETED:
      case ReservationStatus.CHARGED:
        return 'success'
      case ReservationStatus.SUSPENDED:
      case ReservationStatus.CANCELLED:
      case ReservationStatus.ERROR:
        return 'error'
    }
  }
}
