





















import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import {Attachment, Client, Driver, ProfileImageRequest} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

@Component({
  components: {
    BadgeProfileImage: Utils.loadComponent('profile/BadgeProfileImage'),
    Layout: Utils.loadComponent('proxy/Layout'),
    List: Utils.loadComponent('proxy/List/List'),
    ListTile: Utils.loadComponent('proxy/List/ListTile'),
    ListTileContent: Utils.loadComponent('proxy/List/ListTileContent'),
    ListTileTitle: Utils.loadComponent('proxy/List/ListTileTitle'),
    ListTileSubTitle: Utils.loadComponent('proxy/List/ListTileSubTitle'),
    ListTileAction: Utils.loadComponent('proxy/List/ListTileAction'),
  },
})
export default class ProfileWithImage extends Vue {

  @Prop({required: true}) protected driver!: Driver
  @Prop({required: false}) protected client!: Client
  @Prop({
    type: Boolean,
    default: false,
  }) protected small?: boolean
  @Prop({
    type: Boolean,
    default: true,
  }) protected updatePicture?: boolean

  @Action('addProfilePicture') private addProfilePicture!: (payload: ProfileImageRequest) => Promise<string>

  protected get size() {
    return this.small ? 65 : Math.min(0.22 * (this.$vuetify as any).clientWidth, 100)
  }

  protected uploadImage(response: Attachment) {
    return this.addProfilePicture({attachment_token: response.token})
  }
}
