




















import {Vue, Component, Watch} from 'vue-property-decorator'
import {State, Action, Getter} from 'vuex-class'
import {ProfileState} from './store/modules/profile'
import {TimestampsState} from './store/modules/timestamps'
import {EventBus} from './main'
import Utils from './utils'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import {
  AppConfig,
  CheckpointRequest,
  CheckPointResponse,
  HomeModeEnum,
  Popup,
} from '@/lib/kepler/interfaces'

import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import {FirebaseHelper} from '@/lib/FirebaseHelper'
import {DialogState} from '@/store/modules/dialogs'
import {MenuState} from '@/store/modules/menu'
import sdk from '@/lib/kepler/sdk'
import {VApp, VContent} from 'vuetify/lib'

@Component({
  components: {
    VApp,
    VContent,
    AppModals: Utils.loadComponent('AppModals'),
    AppTopBar: Utils.loadComponent('AppTopBar'),
    OfflineAlert: Utils.loadComponent('OfflineAlert'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Layout: Utils.loadComponent('proxy/Layout'),
    HomeMenu: Utils.loadComponent('HomeMenu'),
  },
})
export default class App extends Vue {
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig
  @State((state) => state.configuration.homeMode) public homeMode!: HomeModeEnum
  @State('dialogState') public dialogState!: typeof DialogState
  @State('timestamps') public timestampsState!: TimestampsState
  @State('profile') public profileState!: ProfileState

  @Getter('isLogged') public isLogged!: boolean
  @Getter('isOnline') public isOnline!: boolean
  @Getter('popupIsOpen') public popupIsOpen!: boolean
  @Getter('dialogIsOpen') public dialogIsOpen!: boolean
  @Getter('fullscreenPopup') public fullscreenPopup?: Popup
  @Getter('menu') public homeMenu!: MenuState

  @Action('setOnline') public setOnline!: (v: boolean) => void

  @Action('timestamps') public timestamps: any
  @Action('checkpoint') public checkpoint!: (payload: CheckpointRequest) => Promise<CheckPointResponse>

  protected prefersDark: boolean | null = null
  protected topBarHidden: boolean = false

  protected get statuses(): { [key: string]: string | undefined } {
    if (this.profileState.client && this.profileState.driver) {
      return {
        client: this.profileState.client.status,
        driver: this.profileState.driver.status,
      }
    } else {
      return {
        client: undefined,
        driver: undefined,
      }
    }
  }

  protected get needsNotchFix() {
    // just evaluate if css safe area is set
    const style = getComputedStyle(document.documentElement)
    const topNotch = style.getPropertyValue('--sat')
    return topNotch && topNotch !== '0px'
  }

  @Watch('isLogged')
  @Watch('homeMenu', {deep: true})
  @Watch('$route', {deep: true, immediate: true})
  protected onRouteChange() {
    this.$nextTick(() => {
      if (this.$route.name === 'update') {
        this.topBarHidden = true
        return
      }
      const isFlowHome = this.homeMode.startsWith('flow|') && this.$route.name === 'home'
      this.topBarHidden = isFlowHome || !!this.$route.meta?.topbar?.hidden
    })
  }

  protected get menuHasRoute() {


    switch (this.homeMenu.name) {
      case 'buttons': {
        if (this.$route.name === 'update') { // exceptions
          return false
        }
        return !!this.homeMenu.buttons.find((b) => {
          const excluded = 'exclude' in b ? [this.$route.fullPath, this.$route.name].includes(b.exclude) : false
          return !excluded
          // const path = b.path === this.$route.fullPath
          // const name = b.routeName === this.$route.name
          // console.log(b.path,this.$route.fullPath, '|||',b.routeName, this.$route.name)
          // return !excluded && (path || name)
        })
      }
      case 'logo':
      case 'labeled':
      default:
        return true
    }
  }

  protected open(link: string) {
    window.open(link, '_self')
  }

  @Watch('statuses', {deep: true, immediate: true})
  protected onStatusChange(statuses: any, oldStatuses: any) {
    const changed = JSON.stringify(statuses) !== JSON.stringify(oldStatuses)
    if (oldStatuses && changed) {
      Object.keys(statuses).forEach((key) => {
        if (oldStatuses[key] !== statuses[key] && oldStatuses[key] && statuses[key]) {
          // console.log(key, oldStatuses[key], '=>', key, statuses[key])
          // TODO: get some nice images
          const status = statuses[key].replace(' ', '_')
          this.$dialog.open(ConfirmDialog, {
            props: {
              imageState: status === 'ACTIVE' ? 'success.svg' : null,
              confirmText: this.$t('common.ok'),
              code: '',
              subtitle: this.$t(`profile.notification.${key}.${status}`),
              title: this.$t('profile.edit.profile_updated'),
              singleAction: true,
              emitConfirm: false,
            },
          })
        }
      })
    }
  }

  protected handleFirebase() {
    FirebaseHelper.run((token) => {
      sdk.profile.setFlag('firebase_token', token)
    }, (title: string, body: string, data: any) => {
      if (data.debug) {
        this.$log(JSON.stringify([title, body, data]), 2)
        alert(JSON.stringify([title, body, data]))
      }

      if (data.tap) {
        if (data.openUrl) {
          cordova.InAppBrowser.open(data.openUrl, '_system')
        }
        if (data.path) {
          this.$router.push({path: data.path})
        }
      }

      if (data.operatorPath) {
        const pushPath = () => this.$router.push({path: data.operatorPath})
        if (data.confirmRequest === false || data.confirmRequest === 'false') {
          pushPath()
        } else {
          this.$dialog.open(ConfirmDialogCallback, {
            props: {
              code: '',
              title: this.$t('push.navigation_request.title'),
              subtitle: this.$t('push.navigation_request.subtitle'),
              confirmCallback: pushPath,
            },
          })
        }
      }
    })
  }

  protected async checkOnline() {
    try {
      if (!self.navigator.onLine) { // false is always reliable that no network. true might lie
        return false
      }
      const request = new URL(self.location.origin) // avoid CORS errors with a request to your own origin
      request.searchParams.set('rand', Date.now().toString()) // random value to prevent cached responses
      const response = await fetch(request.toString(), {method: 'HEAD'})
      return response.ok
    } catch {
      return false
    }
  }

  protected created() {
    this.checkOnline().then(this.setOnline)

    if (this.profileState.language) {
      this.$langLoader(this.profileState.language)
    }

    // FEAR OF THE DARK, FEAR OF THE DAAAARK
    this.prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        this.prefersDark = event.matches
      })

    EventBus.$on(['login', 'loginByPhone'], this.handleFirebase)

    if (this.isLogged) {
      // Firebase
      this.handleFirebase()

      // Checkpoint
      const checkpointRequest = {
        device: JSON.parse(JSON.stringify(device)), // device.platform for the device name only
        useragent: navigator.userAgent,
        release: this.$env.RELEASE,
        appversion: this.$env.APP_VERSION_NUMBER.toString(),
        dark: this.prefersDark,
      }
      this.checkpoint(checkpointRequest).then((r) => {
        if (r.status === 'needs-update' || r.status === 'suggest-update') {
          if (this.$route.name !== 'update') {
            this.$router.push({name: 'update', params: {status: r.status}})
          } else {
            Utils.setProp(this.$route, ['meta', 'status'], r.status)
          }
        }
      })
    }
  }

  protected mounted() {
    EventBus.$on('pinReminder', (pin: string) => {
      this.$dialog.open(ConfirmDialog, {
        props: {
          imageState: null,
          code: '',
          title: this.$t('login.pin_reminder.title'),
          subtitle: this.$t('login.pin_reminder.subtitle', {pin}),
          confirmText: this.$t('common.continue'),
          singleAction: true,
        }, hideTopbar: false,
      })
    })

    this.timestamps()
  }

  protected destroyed() {
    EventBus.$off('login')
    EventBus.$off('loginByPhone')
    EventBus.$off('offline')
    EventBus.$off('online')
    window.matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', (event) => {
        this.prefersDark = event.matches
      })
  }
}
