



























import {Vue, Component} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import Utils from '@/utils'
import {VSlider} from 'vuetify/lib'
import {FieldConfigs} from '@/lib/kepler/interfaces'

@Component({
  name: 'RangeFilter',
  components: {
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    FiltersLayout: Utils.loadComponent('filters/FiltersLayout'),
    VSlider,
  },
})
export default class RangeFilter extends Vue {
  @State((state) => state.mapItems?.itemsRequest.min_range || 0) public minRangeState!: number
  @State((state) => !!state.filters.filtersCustom?.meta.range_selector) public readonly customFiltersRange!: boolean
  @State((state) => !!state.filters.filtersDefault?.meta.range_selector) public readonly defaultFiltersRange!: boolean

  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs

  @Action('modifyMapItemsRequest') public modifyRange!: (p: { min_range: number }) => void

  public internalValue: number | string | null = 0

  public get hasMinRange() {
    return this.customFiltersRange || this.defaultFiltersRange
  }

  public get range() {
    if (!this.hasMinRange) {
      return null
    } else {
      return this.minRangeState
    }
  }

  public set range(value) {
    if (this.hasMinRange && value !== null) {
      this.modifyRange({min_range: value})
    }
  }

  public get rangeStep(): string | number | undefined {
    return this.fieldConfigs?.filters_range_step || 30
  }

  public get rangeMax(): string | number | undefined {
    return this.fieldConfigs?.filters_range_max || 450
  }

  public get rangeMin(): string | number | undefined {
    return this.fieldConfigs?.filters_range_min || 0
  }

  public applyRange() {
    if (typeof this.internalValue === 'string') {
      this.internalValue = Number(this.internalValue)
    }
    if (this.range !== this.internalValue) {
      this.range = this.internalValue
    }
  }

  protected created() {
    this.internalValue = this.range
  }
}
