























import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {
  AvailabilityResponse,
  rangeListItem,
} from '@/lib/kepler/interfaces'
import preciseDiff from '@/lib/preciseDiff'
import moment from 'moment'
import {State} from 'vuex-class'

@Component({
  components: {
    RangeGrid: Utils.loadComponent('rangeSelector/rangeGrid'),
    RangeButton: Utils.loadComponent('rangeSelector/rangeButton'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'RangeSelector',
})
export default class RangeSelector extends Vue {
  @State(
    (state) => state.configuration.appConfig.fields_configuration?.quick_range_slots,
  ) public quickRangeSlots?: string

  @Prop() public availability!: AvailabilityResponse[]
  @Prop({type: Boolean, default: false}) public loading!: boolean
  @Prop({type: Boolean, default: false}) public quickExtendButtons!: boolean
  @Prop({type: Boolean, default: false}) public extend!: boolean

  public estimate: any = {}
  public estimateLoader: boolean = false

  protected quickExtendArray: number[] = [1, 2, 4, 8]
  protected quickExtendLabels: string[] = []

  protected rangeList: rangeListItem[] = []

  protected quickExtend(slot: number) {
    this.rangeListReset()
    this.rangeListSelect([0, slot])
  }

  protected created() {
    this.rangeList = this.availability.map((i) => {
      return {
        available: i.available,
        selected: false,
        timestamp: i.start_timestamp,
      }
    })

    if (this.quickRangeSlots && this.quickRangeSlots.split(',').every((v) => !isNaN(Number(v)))) {
      this.quickExtendArray = this.quickRangeSlots.split(',').map((s) => Number(s))
    }

    this.quickExtendArray = this.quickExtendArray.filter((slot) => {
      const valid = !!this.rangeListVerify([0, slot])

      const start = moment(this.availability[0].start)
      const end = moment(this.availability[slot].start)
      const diffObj = preciseDiff(start, end, true)

      const bag: string[] = []
      Object.entries(diffObj).filter(([k, v]) => {
        if (v > 0) {
          bag.push(this.$t('common.time_units.' + k, {
            n: moment(v, k).get(k as moment.unitOfTime.All),
          }) || k)
        }
        return v > 0
      })

      this.quickExtendLabels.push(bag.join(', '))
      return valid
    })
  }

  protected rangeListReset(index?: number) {
    this.rangeList.forEach((i) => {
      i.selected = false
    })
    if (typeof index === 'number') {
      this.rangeList[index].selected = true
    }
  }

  protected rangeListVerify(range: [number, number]) {
    const clone = [...this.rangeList]
    const selection = clone.slice(range[0], range[1] + 1)
    const valid = selection.every((i) => i.available) && (range[0] !== range[1])
    return valid ? clone : false
  }

  protected rangeListSelect(range: [number, number]) {
    const verifiedRange = this.rangeListVerify(range)
    if (verifiedRange) {
      const newRange = [...verifiedRange]
      newRange.forEach((k, i) => {
        k.selected = i >= range[0] && i <= range[1]
      })
      this.$set(this, 'rangeList', newRange)
      this.$emit('rangeUpdated', {
        start: moment.unix(this.rangeList[range[0]].timestamp).format('YYYY-MM-DD HH:mm:ss'),
        end: moment.unix(this.rangeList[range[1]].timestamp).format('YYYY-MM-DD HH:mm:ss'),
      })
    } else {
      this.rangeListReset()
    }
  }
}
