






































import {Vue, Component, Prop} from 'vue-property-decorator'
import {VehicleSlot} from '@/lib/kepler/interfaces'
import Utils from '../utils'

import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import ServiceMesh from '@/lib/serviceMesh'

@Component({
  components: {
    Chip: Utils.loadComponent('proxy/Chip'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Img: Utils.loadComponent('proxy/Image'),
    Plate: Utils.loadComponent('Plate'),
    FuelLevel: Utils.loadComponent('FuelLevel'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Container: Utils.loadComponent('proxy/Container'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
  },
  name: 'CardVehicleList',
})
export default class CardVehicleList extends Vue {
  @Prop() public vehicleSlot!: VehicleSlot
  @Prop() public estimate!: number
  @Prop() public estimateLoading!: boolean

  public get typeKey() {
    return `${this.vehicleSlot.reservation_type}${this.vehicleSlot.vehicle.category.type}`.toUpperCase()
  }

  public get parsedRate() {
    const r = this.vehicleSlot.base_rate
    const parsedistance = (val: string) => this.$distance(val && Number(val) !== 1 ? val : undefined)
    if (r) {
      const [timeCost, timeCycle] = r.info.time_rate?.split('|') ?? [null, null]
      const [distanceCost, distanceCycle] = r.info.distance_rate?.split('|') ?? [null, null]
      return {
        time: {
          cost: timeCost !== null ? this.$currency(timeCost) : null,
          cycle: timeCycle !== null ? this.$parseMinutes(timeCycle) : null,
        },
        distance: {
          cost: distanceCost !== null ? this.$currency(distanceCost) : null,
          cycle: distanceCycle !== null ? parsedistance(distanceCycle) : null,
        },
      }
    }
  }

  protected get vehicleTypeColor() {
    const key = `${this.vehicleSlot.reservation_type}${this.vehicleSlot.vehicle.category.type}`.toUpperCase()
    return {color: ServiceMesh.colors[key]}
  }

  protected canBeBooked(event: Event) {
    if (!this.estimateLoading) {
      if (this.estimate === null) {
        this.$dialog.open(ConfirmDialogCallback, {
          props: {
            code: '',
            title: this.$t('booking.your_plan_is_not_suitable'),
            subtitle: '',
            confirmText: '',
            imageState: 'warn.svg',
            confirmCallback: () => {
              return 1
            },
          },
        })
      } else {
        this.$emit('selectVehicle', event)
      }
    }
  }
}
