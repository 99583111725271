















import {Component, Prop, Watch} from 'vue-property-decorator'
import Utils from '@/utils'
import {BookRequest, VehicleSlot, BookTimeSelectRequest, ReservationEditRequest} from '@/lib/kepler/interfaces'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    VehicleQuickSummary: Utils.loadComponent('entities/vehicle/VehicleQuickSummary'),
    Divider: Utils.loadComponent('proxy/Divider'),
    DateTimeRangeField: Utils.loadComponent('fields/DateTimeRangeField'),
    Container: Utils.loadComponent('proxy/Container'),
    RangeEstimate: Utils.loadComponent('rangeSelector/rangeEstimate'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'VehicleBookingDateTimeSelect',
})
export default class VehicleBookingDateTimeSelect extends ConfirmDialogCallback {
  @Prop() public readonly vehicleSlot!: VehicleSlot
  @Prop() public bookRequest!: BookRequest
  declare public confirmCallback: (r: ReservationEditRequest) => void

  protected valid = false
  protected innerValue: BookTimeSelectRequest = {start: '', end: ''}

  @Watch('innerValue', {deep: true})
  protected isValid(val: BookTimeSelectRequest) {
    if (!!val.start && !!val.end) {
      this.valid = true
      this.bookRequest.start = val.start
      this.bookRequest.end = val.end
    }
  }
}
