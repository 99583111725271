





























import {Vue, Component, Prop} from 'vue-property-decorator'
import Layout from '@/components/proxy/Layout.vue'
import Divider from '@/components/proxy/Divider.vue'
import Icon from '@/components/proxy/Icon.vue'
import CardList from '@/components/CardList.vue'
import {TransitRoute, TransitTimeline} from '@/lib/kepler/interfaces'
import DateHelper from '@/lib/DateHelper'
import moment from 'moment'

@Component({
  components: {CardList, Icon, Divider, Layout},
})
export default class TransitListItem extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) protected route!: TransitRoute

  protected parseMinutes(min: string | number) {
    const m = Number(min)
    if (!Number.isNaN(m)) {
      return DateHelper.parseMinutes(this, m)
    }
  }

  protected vehicleIcon(v: string) {
    switch (v) {
      case 'bus':
        return 'mdi-bus'
      case 'van':
        return 'mdi-van-passenger'
      case 'horse':
        return 'mdi-horse'
      default:
        return null
    }
  }

  protected get firstStop() {
    return this.route.timeline[0]
  }

  protected get lastStop() {
    return this.route.timeline.slice(-1)[0]
  }

  protected dateIfNotToday(t?: TransitTimeline) {
    const m = moment(t?.datetime || this.route.departure_datetime, 'YYYY-MM-DD HH:mm:ss')
    return m.isSame(moment(), 'day') ? null : m.format('DD MMM')
  }

}
