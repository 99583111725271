



















import {Vue, Component, Prop, VModel} from 'vue-property-decorator'
import ClickOutside from '@/lib/ClickOutside.vue'
import {EVChargingStation, ParkingLot, VehicleSlot} from '@/lib/kepler/interfaces'
import VehicleCardPopup from '@/components/entities/vehicle/VehicleCardPopup.vue'
import ParkingCardPopup from '@/components/entities/vehicle/ParkingCardPopup.vue'
import Utils from '@/utils'
import EVChargingCardPopup from '@/components/entities/vehicle/EVChargingCardPopup.vue'
import {LatLng} from '@/lib/n-maps/src/models/LatLng'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    ClickOutside,
  },
})
export default class CarouselContainer extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  }) public elements!: VehicleSlot[] | ParkingLot[] | EVChargingStation[] | []

  @VModel({
    type: String,
    default: 'hidden',
  }) public type!: 'hidden' | 'vehicle' | 'parking' | 'poi' | 'evCharging'

  public get active() {
    return this.type !== 'hidden'
  }

  public set active(val: boolean) {
    if (!val) {
      this.type = 'hidden'
    }
  }

  public get component() {
    switch (this.type) {
      case 'vehicle':
        return VehicleCardPopup
      case 'parking':
        return ParkingCardPopup
      case 'poi':
        return 'html'
      case 'evCharging':
        return EVChargingCardPopup
      default:
        return null
    }
  }

  public get parkingLot() {
    if (this.elements.length) {
      let first = this.elements[0]
      switch (this.type) {
        case 'vehicle':
          first = first as VehicleSlot
          return {
            name: (first).lot.name,
            distance: this.getDistance(first) || null,
          }
        case 'parking':
          first = first as ParkingLot
          return {
            name: first.name,
            distance: null,
          }
        case 'evCharging':
          first = first as EVChargingStation
          return {
            name: first.address,
            distance: first.distance ? this.$distance(Math.round((first.distance / 1000) * 10) / 10) : null,
          }
        default:
          return null
      }
    }
    return null
  }

  protected get position() {
    const first = this.elements[0]
    if ('reservation_type' in first) {
      return LatLng.parse(first.lot.position)
    } else if ('position' in first) {
      return LatLng.parse(first.position)
    } else {
      return LatLng.parse({lat: first.lat, lng: first.lng})
    }
  }

  public parsedProps(element: any) {
    switch (this.type) {
      case 'vehicle':
        return {vehicleSlot: element, key: element.id}
      case 'parking':
        return {lot: element, key: element.id}
      case 'poi':
        return {poi: element, key: element.id}
      case 'evCharging':
        return {station: element, key: element.id}
    }
  }

  public close() {
    this.active = false
  }

  protected openInMaps() {
    if (this.position) {
      const destination = `&destination=${this.position.lat},${this.position.lng}`
      const url = `https://www.google.com/maps/dir/?api=1${destination}&=travelmode=walking`
      cordova.InAppBrowser.open(url, '_system')
    }
  }

  protected getDistance(vehicleSlot: VehicleSlot) {
    const d = vehicleSlot.distance
    let str: string | null = null
    if (d !== null) {
      str = `${this.$t('common.circa')} `
      if (this.$distance().toLowerCase() === 'km') {
        str += d >= 1000 ? `${this.$distance(Math.round((d / 1000) * 10) / 10)}` : `${Math.floor(d)}m`
      } else {
        str += this.$distance(d)
      }
    }
    return str
  }
}
