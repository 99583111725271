<script>
  import Colorable from 'vuetify/src/mixins/colorable'
  import Themeable from 'vuetify/lib/mixins/themeable'
  import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
  import {VProgressLinear} from 'vuetify/es5/components/VProgressLinear'
  import {VFadeTransition, VSlideXTransition} from 'vuetify/lib'
  import {convertToUnit} from 'vuetify/lib/util/helpers'

  export default {
    name: 'ProgressLinear',
    extends: VProgressLinear,
    mixins: [Colorable, Themeable],
    props: {
      inverted: {
        type: Boolean,
        default: false,
      },
      content: {
        type: String,
        default: '',
      },
    },
    computed: {
      styles() {
        const styles = {}

        if (!this.active) {
          styles.height = 0
        }

        if (!this.indeterminate && this.normalizedBufer !== 100) {
          styles.clipPath = `polygon(0 0, ${this.normalizedBufer}% 0, ${this.normalizedBufer}% 100%, 0 100%)`
        }
        if (this.inverted) {
          styles.marginLeft = `${100 - this.effectiveWidth}%`
        }
        if (this.content) {
          styles.position = 'absolute'
        }

        return styles
      },
    },
    /* tslint:disable: cyclomatic-complexity */
    render(h) {
      const fade = h(VFadeTransition, this.indeterminate ? [this.genIndeterminate(h)] : [])
      const slide = h(VSlideXTransition, this.indeterminate ? [] : [this.genDeterminate(h)])

      const bar = h('div', {
        staticClass: 'v-progress-linear__bar',
        style: this.styles,
      }, [fade, slide])

      const background = h('div', this.setBackgroundColor(this.backgroundColor || this.color, {
        staticClass: 'v-progress-linear__background',
        style: this.backgroundStyle,
      }))

      const defaultSlot = this.$slots.default && h('div', {
        staticClass: 'v-progress-linear__content',
      }, this.$slots.default)

      const light = VuetifyColorHelper.lightContrast(this.backgroundColor || this.color)

      const content = this.content && h('div', this.setTextColor(light ? 'black' : 'white', {
        staticClass: 'v-progress-linear__custom-content text-xs-right',
        staticStyle: {},
      }), [
        h('div', {staticClass: 'v-progress-linear__custom-content--mask', style: {height: this.styles.height}},
          [h('div', {staticClass: 'v-sheet v-sheet--tile absolute-fill ' + (this.isDark ? 'theme--dark' : 'theme--light')})],
        ),
        h('div', {staticClass: 'v-progress-linear__custom-content--text subheading px-3'}, this.content)])

      return h('div', {
        staticClass: 'v-progress-linear',
        attrs: {
          'role': 'progressbar',
          'aria-valuemin': 0,
          'aria-valuemax': this.normalizedBufer,
          'aria-valuenow': this.indeterminate ? undefined : this.normalizedValue,
        },
        class: {
          'v-progress-linear--query': this.query,
        },
        style: {
          height: convertToUnit(this.height),
        },
        on: this.$listeners,
      }, [
        background,
        bar,
        defaultSlot,
        content,
      ])
    },
  }

  //// You can develop it in typescript, but convert it in plain js
  //// or it will try to lint the whole vuetify.

  // import {Component, Prop} from 'vue-property-decorator'
  //
  // import {convertToUnit} from 'vuetify/src/util/helpers'
  // import VProgressLinear from 'vuetify/src/components/VProgressLinear/VProgressLinear'
  // import {VFadeTransition, VSlideXTransition} from 'vuetify/lib'
  // import {CreateElement} from 'vue'
  // import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
  // import Colorable from 'vuetify/src/mixins/colorable'
  // import Themeable from 'vuetify/src/mixins/themeable'
  //
  // @Component({name: 'ProgressLinear', mixins: [Colorable, Themeable]})
  // export default class ProgressLinear extends VProgressLinear {
  //   @Prop({type: Boolean, default: false}) public inverted!: boolean
  //   @Prop({type: String, default: ''}) public content!: string
  //
  //   /* tslint:disable: cyclomatic-complexity */
  //   public render(h: CreateElement) {
  //     const fade = h(VFadeTransition, this.indeterminate ? [this.genIndeterminate(h)] : [])
  //     const slide = h(VSlideXTransition, this.indeterminate ? [] : [this.genDeterminate(h)])
  //
  //     const bar = h('div', {
  //       staticClass: 'v-progress-linear__bar',
  //       style: this.styles,
  //     }, [fade, slide])
  //
  //     const background = h('div', this.setBackgroundColor(this.backgroundColor || this.color, {
  //       staticClass: 'v-progress-linear__background',
  //       style: this.backgroundStyle,
  //     }))
  //
  //     const defaultSlot = this.$slots.default && h('div', {
  //       staticClass: 'v-progress-linear__content',
  //     }, this.$slots.default)
  //
  //     const light = VuetifyColorHelper.lightContrast(this.backgroundColor || this.color)
  //
  //     const content = this.content && h('div', this.setTextColor(light ? 'black' : 'white', {
  //       staticClass: 'v-progress-linear__custom-content text-xs-right',
  //       staticStyle: {},
  //     }), [
  //       h('div', {staticClass: 'v-progress-linear__custom-content--mask', style: {height: this.styles.height}},
  //         [h('div', {staticClass: 'v-sheet theme--light v-sheet--tile absolute-fill'})],
  //       ),
  //       h('div', {staticClass: 'v-progress-linear__custom-content--text subheading px-3'}, this.content)])
  //
  //     return h('div', {
  //       staticClass: 'v-progress-linear',
  //       attrs: {
  //         'role': 'progressbar',
  //         'aria-valuemin': 0,
  //         'aria-valuemax': this.normalizedBufer,
  //         'aria-valuenow': this.indeterminate ? undefined : this.normalizedValue,
  //       },
  //       class: {
  //         'v-progress-linear--query': this.query,
  //       },
  //       style: {
  //         height: convertToUnit(this.height),
  //       },
  //       on: this.$listeners,
  //     }, [
  //       background,
  //       bar,
  //       defaultSlot,
  //       content,
  //     ])
  //   }
  // }
</script>
<style lang="sass">
  .v-progress-linear
    z-index: 0

  .v-progress-linear__custom-content
    position: relative
    display: flex
    align-items: center
    z-index: 1
    height: 100%

    &--mask
      position: relative
      z-index: 1
      flex-grow: 1
      height: calc(100% - 4px)
      align-self: flex-start

    &--text
      flex-shrink: 1
</style>
