





































import {Component, Prop, Vue} from 'vue-property-decorator'
import {EstimateCategories, SearchRequest, VehicleSlot} from '@/lib/kepler/interfaces'
import moment from 'moment'
import Utils from '@/utils'

@Component({
  components: {
    CardVehicleList: Utils.loadComponent('CardVehicleList'),
    Container: Utils.loadComponent('proxy/Container'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Button: Utils.loadComponent('Button'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
  },
  name: 'VehicleListing',
})

export default class VehicleListing extends Vue {
  @Prop() public vehicleSlots!: VehicleSlot[]
  @Prop() public searchRequest!: SearchRequest
  @Prop() public estimateCategories!: EstimateCategories[]
  @Prop() public estimateLoading!: boolean

  public get slotsByLots() {
    const result: Record<string, VehicleSlot[]> = {}
    for (const vs of this.vehicleSlots) {
      if (!result[vs.name]) {
        result[vs.name] = [vs]
      } else {
        result[vs.name].push(vs)
      }
    }
    return result
  }

  private formatDistance(dist: number) {
    const num = (Math.round(dist) / 1000)
    return Math.round(num * 100) / 100
  }

  private formatTime(dateTime: string) {
    const t = moment(dateTime, 'YYYY-MM-DD HH:mm:ss')
    return t.format(this.$dateformat()) + '\n ' + t.format(this.$timeformat())
  }

  private categoryEstimate(catId: string) {
    const c = this.estimateCategories.find((item) => item.rate.id === catId)
    return c && c.costs ? c.costs.time_cost : null
  }
}
