













































import {Vue, Component} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import Utils from '@/utils'
import {Packet, VehicleCategory} from '@/lib/kepler/interfaces'
import DateHelper from '@/lib/DateHelper'
import {VItem, VItemGroup} from 'vuetify/lib'
import Container from '@/components/proxy/Container.vue'
import Flex from '@/components/proxy/Flex.vue'
import sdk from '@/lib/kepler/sdk'
import GradientCard from '@/components/GradientCard.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import Chip from '@/components/proxy/Chip.vue'
import Divider from '@/components/proxy/Divider.vue'

@Component({
  components: {
    Divider,
    Chip,
    GradientCard,
    Flex,
    Container,
    VItemGroup,
    VItem,
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
    IsLogged: Utils.loadComponent('IsLogged'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
  },
})
export default class AddPacket extends Vue {
  @State((state) => state.wallet.packets) public packets?: Packet[]
  // @State((state) => state.wallet.activePackets) public activePackets?: ActivePacket[]
  // @Action('getActivePackets') public getActivePackets!: () => Promise<ActivePacket>
  @Action('getProfile') public getProfile!: () => Promise<void>
  @Action('getPackets') public getPackets!: () => Promise<Packet>

  public loading: string | false = false
  public selectedPacket: string | null = null
  // public showPurchasable: boolean = false
  @State((state) => state.booking.categories) protected categories!: VehicleCategory[]

  // public get showActivePackets() {
  //   return !!this.activePackets?.length || this.showPurchasable
  // }

  public created() {
    this.loading = 'initial'
    Promise.allSettled([
      this.getPackets(),
      // this.getActivePackets(),
    ]).finally(() => {
      this.loading = false
    })
  }

  public text(p: Packet) {
    if (this.$isAvailable('wallet.packets.text')) {
      const m = p.minutes && Number(p.minutes) ? DateHelper.humanizeDuration(p.minutes, 'minutes') : ''
      const d = p.distance && Number(p.distance) ? this.$distance(p.distance) : ''
      const t = `${m}${m && d ? ' | ' : ''}${d}`

      return this.$t('wallet.packets.text', {t})
    }
  }

  public purchase() {
    if (!this.packets || this.selectedPacket === null) {
      return
    }
    const pkt = this.packets.find((p) => p.name === this.selectedPacket)
    if (pkt) {
      this.$dialog.open(ConfirmDialogCallback, {
        props: {
          code: '',
          title: this.$t('wallet.packets.warning'),
          subtitle: '',
          confirmColor: 'warning',
          imageState: '',
          showCloseButton: false,
          closeOnCallback: false,
          cancelCallback: this.$dialog.close,
          confirmCallback: () => {
            this.loading = pkt.id
            sdk.billing.packets.purchase(pkt.id)
              .then(() => {
                this.getProfile()
                this.$dialog.close()
                this.loading = false
                this.$nextTick(() => {
                  this.$dialog.open(ConfirmDialogCallback, {
                    props: {
                      code: '',
                      title: this.$t('wallet.packets.confirmed'),
                      subtitle: '',
                      confirmColor: 'success',
                      imageState: '',
                      showCloseButton: false,
                      confirmCallback: this.$dialog.close,
                    },
                  })
                })
              })
          },
        },
      })
    }
  }

  public humanize(str: string | number, unit = 'minutes') {
    return DateHelper.humanizeDuration(Number(str), unit)
  }
}
