
































































































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import Utils from '@/utils'
import {BookRequest, FieldConfigs, Packet, VehicleSlot} from '@/lib/kepler/interfaces'
import ConfirmDialogCallback from '../ConfirmDialogCallback.vue'
import storage from '@/lib/storage'
import {
  VDialog,
  VDivider,
  VExpansionPanel,
  VExpansionPanelContent,
  VListTileAction,
  VListTileActionText,
  VListTileAvatar,
  VListTileContent,
  VListTileSubTitle,
  VListTileTitle,
  VSelect,
} from 'vuetify/lib'
import DateHelper from '@/lib/DateHelper'

@Component({
  components: {
    Subheader: Utils.loadComponent('proxy/Subheader'),
    CardList: Utils.loadComponent('CardList'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Icon: Utils.loadComponent('proxy/Icon'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Chip: Utils.loadComponent('proxy/Chip'),
    ConfirmDialogCallback,
    VehicleConfirmImage: Utils.loadComponent('entities/vehicle/VehicleConfirmImage'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Container: Utils.loadComponent('proxy/Container'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    IsLogged: Utils.loadComponent('IsLogged'),
    Button: Utils.loadComponent('Button'),
    VSelect,
    VListTileContent,
    VListTileAvatar,
    VListTileTitle,
    VListTileSubTitle,
    VListTileAction,
    VListTileActionText,
    VExpansionPanel,
    VExpansionPanelContent,
    VDivider,
    VDialog,
  },
  name: 'VehicleBookingAddMemoDialog',
})
export default class VehicleBookingAddMemoDialog extends ConfirmDialogCallback {
  @State((state) => state.wallet.packets) public packets?: Packet[]
  @Action('getPackets') public getPackets!: () => Promise<Packet>
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs
  @Prop({type: Object, required: true}) public bookRequest!: BookRequest
  @Prop({type: Object, required: false}) public vehicleSlot?: VehicleSlot

  public memos: string[] = []
  public memo: string | null = ''
  public prepended: string | null = ''

  public editMemos: number | null = null
  public memoToAdd: string = ''
  public packetToAdd: string | null = null

  public rateDetailDialog: boolean = false
  public packetDetailDialog: boolean = false
  public packetDetailSelected: Packet | null = null

  public created() {
    this.getPackets()
    this.getMemos()
  }

  protected submitMemo() {
    this.bookRequest.memo = this.memo ? this.prepended + ' ' + this.memo : this.prepended
    this.bookRequest.packet_id = this.packetToAdd || null
    this.callAndClose(this.confirmCallback)
    this.loading = true
  }

  protected toggleRateDetailDialog() {
    this.rateDetailDialog = !this.rateDetailDialog
  }

  protected togglePacketDetailDialog(packet?: Packet) {
    this.packetDetailSelected = packet || null
    this.packetDetailDialog = !!this.packetDetailSelected
  }

  protected getMemos() {
    this.memos = storage.load('memos') || []
    if (!this.memos) {
      storage.save('memos', [])
    }
  }

  protected selectMemo(memo: string) {
    this.prepended = memo
    this.editMemos = null
  }

  protected addMemoToStorage() {
    const memo = this.memoToAdd
    if (memo) {
      this.memos.push(memo)
      storage.save('memos', this.memos)
      this.memoToAdd = ''
      this.prepended = memo
      this.editMemos = null
    }
  }

  protected removeMemoFromStorage(memo: string) {
    this.memos = this.memos.filter((m) => m !== memo)
    if (this.prepended === memo) {
      this.prepended = null
    }
    storage.save('memos', this.memos)
  }

  protected humanize(str: string | number, unit = 'minutes') {
    return DateHelper.humanizeDuration(Number(str), unit)
  }

  protected get hideVouchers() {
    return !!this.fieldConfigs?.hide_vouchers
  }

  protected get showUpsell() {
    return !!this.vehicleSlot?.base_rate && !!this.packets?.length && !!this.fieldConfigs?.upsell_packets
  }

  protected get voucherChipBgImage() {
    const bgColor = window.getComputedStyle((this.$refs.card as Vue).$el).backgroundColor
    return `radial-gradient(${bgColor} 0, ${bgColor} 5px, rgba(255, 255, 255, 0) 5px)`
  }

  public get parsedRate() {
    const r = this.vehicleSlot?.base_rate
    const parsedistance = (val: string) => this.$distance(val && Number(val) !== 1 ? val : undefined)
    if (r) {
      const info = r.info
      const [timeCost, timeCycle] = r.info?.time_rate?.split('|') ?? [null, null]
      const [distanceCost, distanceCycle] = r.info?.distance_rate?.split('|') ?? [null, null]
      const time = timeCost !== null && timeCycle !== null ? {
        cost: this.$currency(timeCost),
        cycle: this.$parseMinutes(timeCycle),
      } : null
      const distance = distanceCost !== null && distanceCycle !== null ? {
        cost: this.$currency(distanceCost),
        cycle: parsedistance(distanceCycle),
      } : null
      const usageCost = r.info?.usage_cost || null
      return {time, distance, usageCost, info}
    }
  }
}
