





























import {Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import {BookRequest, BookTimeSelectRequest, Estimate, EstimateRequest, VehicleSlot} from '@/lib/kepler/interfaces'

import moment from 'moment'
import Utils from '@/utils'

import VehicleBookingAddMemoDialog from '@/views/Vehicle/VehicleBookingAddMemoDialog.vue'
import {mixins} from 'vue-class-component'
import BookingMixin from '@/lib/BookingMixin'

@Component({
  components: {
    RangeEstimate: Utils.loadComponent('rangeSelector/rangeEstimate'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Btn: Utils.loadComponent('proxy/Btn'),
    Button: Utils.loadComponent('Button'),
    Chip: Utils.loadComponent('proxy/Chip'),
    CircleStatus: Utils.loadComponent('CircleStatus'),
    Container: Utils.loadComponent('proxy/Container'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Layout: Utils.loadComponent('proxy/Layout'),
    VehicleInstructions: Utils.loadComponent('VehicleInstructions'),
    VehicleLocation: Utils.loadComponent('entities/vehicle/VehicleLocation'),
    VehicleDetail: Utils.loadComponent('entities/vehicle/VehicleDetail'),
    VehicleQuickInfo: Utils.loadComponent('entities/vehicle/VehicleQuickInfo'),
    VehicleTypeChip: Utils.loadComponent('entities/vehicle/VehicleTypeChip'),
  },
  name: 'VehicleBookingSummary',
  mixins: [BookingMixin],
})
export default class VehicleBookingSummary extends mixins<BookingMixin>(BookingMixin) {
  @Action('bookEstimate') public bookEstimate!: (r: EstimateRequest) => Promise<Estimate>

  @Prop() protected vehicleSlot!: VehicleSlot
  @Prop() protected reservationRequest!: BookRequest

  protected estimateRequest: BookTimeSelectRequest | null = null

  protected formatTime(dateTime: string) {
    const t = moment(dateTime, 'YYYY-MM-DD HH:mm:ss')
    return t.format(this.$dateformat()) + '\n ' + t.format(this.$timeformat())
  }

  protected book() {
    this.sendBooking()
    if (this.disableMemo(this.vehicleSlot.reservation_type, this.vehicleSlot.vehicle.category.type)) {
      this.sendBooking()
    } else {
      this.$dialog.open(VehicleBookingAddMemoDialog, {
        props: {
          vehicleSlot: this.vehicleSlot,
          bookRequest: this.bookRequest,
          confirmCallback: () => this.sendBooking(),
        },
      })
    }
  }

  protected created() {
    this.bookRequest = this.reservationRequest
    this.estimateRequest = {start: this.bookRequest.start, end: this.bookRequest.end}
  }
}
