

























import {Component, Vue} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  components: {
    Img: Utils.loadComponent('proxy/Image'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Container: Utils.loadComponent('proxy/Container'),
  },
})

export default class CustomHome extends Vue {
  protected action(num: number) {
    switch (num) {
      case 1:
        this.$router.push({name: 'bookingRequest'})
        break
      case 2:
        this.$router.push({path: '/activities/booking/kickscooter/ff'})
        break
      case 3:
        this.$router.push({name: 'bus'})
        break
      case 4:
        this.$router.push({name: 'map'})
        break
    }
  }

  protected imgPath(num: number) {
    return `https://storage.ksa.playmoove.com/playmoove-samara-storage-public/buttons/${num}.png`
  }

  protected created() {
    if (!this.$isLogged()) {
      this.$router.replace({name: 'login'})
    }
  }
}
