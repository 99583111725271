















































































































































import {Component, Prop, Vue} from 'vue-property-decorator'
import Utils from '@/utils'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Icon: Utils.loadComponent('proxy/Icon'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
  },
})
export default class DevButtons extends Vue {
  @Prop({type: Array}) protected icons!: string[]

  protected buttonProps = {
    small: false,
    proxied: true,
    smallClass: false,
    large: false,
    block: false,
    round: false,
    stroked: false,
    loading: false,
    icon: false,
    textOrIcon: false,
    flat: false,
    fab: false,
    disabled: false,
    depressed: false,
    color: 'buttonDefaultColor',
    text: 'text',
    iconName: 'mdi-cheese-off',
  }

  protected bool: boolean = false
}
